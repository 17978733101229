import React, { JSX } from 'react';
import { Routes, Route, useSearchParams } from 'react-router-dom';
import { Grid, Typography } from '@mui/material';
import { SupportedModule } from '@apus/common-lib/integrations/src/interface';

function AuthorizeCodeResult({
  connection,
}: {
  connection: SupportedModule;
}): JSX.Element {
  const [searchParams] = useSearchParams();

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        {searchParams.get('code') && (
          <Typography>Code received for connection ${connection}</Typography>
        )}
        {searchParams.get('error') && (
          <Typography>
            Authorization code could not be received: $
            {searchParams.get('error')}
          </Typography>
        )}
      </Grid>
    </Grid>
  );
}

// TODO: I'm not sure if this is needed at all
const OAuthCodeRedirectView = (): JSX.Element => {
  return (
    <Routes>
      <Route
        path="authorize/microsoft-graph"
        element={<AuthorizeCodeResult connection={'microsoft-graph'} />}
      />
      <Route
        path="authorize/pagero"
        element={<AuthorizeCodeResult connection={'pagero'} />}
      />
      <Route
        path="authorize/clickup"
        element={<AuthorizeCodeResult connection={'clickup'} />}
      />
      <Route
        path="authorize/nordea-business"
        element={<AuthorizeCodeResult connection={'nordea-business'} />}
      />
      <Route
        path="authorize/qonto"
        element={<AuthorizeCodeResult connection={'qonto'} />}
      />
    </Routes>
  );
};

export default OAuthCodeRedirectView;
