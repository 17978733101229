import React, { JSX, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import {
  BankTransactionHandlingRule,
  BankTransactionHandlingRuleHandler,
  BankTransactionIntegrationAppConfiguration,
  ErpEntityIdentificationRule,
  ErpEntityIdentificationRuleHandler,
  ErpTransactionIdentificationRule,
  ErpTransactionIdentificationRuleHandler,
} from '@apus/common-lib/api/interface/apps/bank-transaction-integration';
import {
  bankConnections,
  erpConnections,
} from '@apus/common-lib/api/interface/apps/bank-transaction-integration';
import { SystemglueAppName } from '@apus/common-lib/api/interface/common';
import { BankTransactionIntegrationApp } from '@apus/common-lib/api/interface/subscriptions';
import {
  defaultBankTransactionHandlingRules,
  defaultErpEntityIdentificationRules,
  defaultErpTransactionIdentificationRules,
} from '@apus/common-lib/integrations/src/banking/transactions/rules-default';
import HookFormTextInput from '@apus/common-ui/components/hook-form/HookFormTextInput';
import { LoadingButton } from '@mui/lab';
import { Grid, Stack, Typography } from '@mui/material';

import RuleList from './banking/transaction/rule/RuleList';
import useNetsuiteListAccounts from '../../apis/hooks/apps/banking/transactions/useNetsuiteListAccounts';
import Collapsible from '../../surface/Collapsible';
import AppRequirementConfiguration from '../AppRequirementConfiguration';
import { PriorityArray } from '@apus/common-lib/utils/src/collections';

interface Props {
  pending?: boolean;
  value: BankTransactionIntegrationAppConfiguration;
  edit?: boolean;
  onSave: (
    configuration: BankTransactionIntegrationAppConfiguration,
    subscriptionName?: string
  ) => void;
  onCancel: () => void;
}

type EntityRule = ErpEntityIdentificationRule<
  ErpEntityIdentificationRuleHandler,
  any
>;

type TransactionRule = ErpTransactionIdentificationRule<
  ErpTransactionIdentificationRuleHandler,
  any
>;

type HandlingRule = BankTransactionHandlingRule<
  BankTransactionHandlingRuleHandler,
  any
>;

function createRuleArray<T extends EntityRule | TransactionRule | HandlingRule>(
  rules: T[]
): PriorityArray<T, string> {
  return new PriorityArray<T, string>(item => item.ruleId, rules);
}

export function createEmptyBankTransactionIntegrationApp(): BankTransactionIntegrationApp {
  return {
    name: SystemglueAppName.BANK_TRANSACTION_INTEGRATION_APP,
    configuration: {
      connections: {
        erp: 'netsuite',
        bank: 'osuuspankki',
      },
      transactions: {
        numberFieldName: '',
        numberFieldName2: '',
        numberFieldName3: '',
        numberFieldName4: '',
        numberFieldName5: '',
        referenceFieldName: '',
        referenceFieldName2: '',
        referenceFieldName3: '',
        referenceFieldName4: '',
        referenceFieldName5: '',
      },
      general: {
        dateFormat: '',
        roundingTolerance: 0,
      },
      account: {
        iban: '',
        bic: '',
      },
      rules: {
        bankTransactionHandling: defaultBankTransactionHandlingRules,
        erpEntityIdentification: defaultErpEntityIdentificationRules,
        erpTransactionIdentification: defaultErpTransactionIdentificationRules,
      },
    },
  };
}

const ConfigureBankTransactionSubscription = ({
  pending,
  value,
  edit = false,
  onSave,
}: Props): JSX.Element => {
  const [listAccountPending, listAccountResult, listAccountError] =
    useNetsuiteListAccounts([value]);
  const form = useForm<BankTransactionIntegrationAppConfiguration>();

  const [
    workingEntityIdentificationRules,
    setWorkingEntityIdentificationRules,
  ] = useState<PriorityArray<EntityRule, string>>(
    createRuleArray<EntityRule>([])
  );
  const [
    workingTransactionIdentificationRules,
    setWorkingTransactionIdentificationRules,
  ] = useState<PriorityArray<TransactionRule, string>>(
    createRuleArray<TransactionRule>([])
  );
  const [workingHandlingRules, setWorkingHandlingRules] = useState<
    PriorityArray<HandlingRule, string>
  >(createRuleArray<HandlingRule>([]));

  useEffect(() => {
    setWorkingEntityIdentificationRules(
      createRuleArray(
        value.rules?.erpEntityIdentification === undefined ||
          value.rules.erpEntityIdentification.length === 0
          ? defaultErpEntityIdentificationRules
          : value.rules.erpEntityIdentification
      )
    );
    setWorkingTransactionIdentificationRules(
      createRuleArray(
        value.rules?.erpTransactionIdentification === undefined ||
          value.rules.erpTransactionIdentification.length === 0
          ? defaultErpTransactionIdentificationRules
          : value.rules.erpTransactionIdentification
      )
    );
    setWorkingHandlingRules(
      createRuleArray(
        value.rules?.bankTransactionHandling === undefined ||
          value.rules.bankTransactionHandling.length === 0
          ? defaultBankTransactionHandlingRules
          : value.rules.bankTransactionHandling
      )
    );
  }, [
    value,
    setWorkingEntityIdentificationRules,
    setWorkingTransactionIdentificationRules,
    setWorkingHandlingRules,
  ]);

  useEffect(() => {
    form.setValue('connections', value.connections);
    form.setValue('account', value.account);
    form.setValue('general', value.general);
    form.setValue('transactions', value.transactions);
  }, [value, form]);

  function onSubmit(configuration: BankTransactionIntegrationAppConfiguration) {
    const subscriptionName = `${configuration.connections.bank} - ${configuration.account.iban}`;
    const updated: BankTransactionIntegrationAppConfiguration = {
      ...configuration,
      rules: {
        erpEntityIdentification: workingEntityIdentificationRules.list(),
        erpTransactionIdentification:
          workingTransactionIdentificationRules.list(),
        bankTransactionHandling: workingHandlingRules.list(),
      },
    };
    onSave(updated, subscriptionName);
  }

  function onEntityIdentificationRuleChange(rule: EntityRule) {
    if (rule !== undefined && rule.ruleId !== undefined) {
      setWorkingEntityIdentificationRules(
        workingEntityIdentificationRules.update(rule).clone()
      );
    }
  }

  function onTransactionIdentificationRuleChange(rule: TransactionRule) {
    if (rule !== undefined && rule.ruleId !== undefined) {
      setWorkingTransactionIdentificationRules(
        workingTransactionIdentificationRules.update(rule).clone()
      );
    }
  }

  function onHandlingRuleChange(rule: HandlingRule) {
    if (rule !== undefined && rule.ruleId !== undefined) {
      setWorkingHandlingRules(workingHandlingRules.update(rule).clone());
    }
  }

  function onEntityRuleMovedUp(rule: EntityRule) {
    setWorkingEntityIdentificationRules(
      workingEntityIdentificationRules.up(rule).clone()
    );
  }

  function onEntityRuleMovedDown(rule: EntityRule) {
    setWorkingEntityIdentificationRules(
      workingEntityIdentificationRules.down(rule).clone()
    );
  }

  function onTransactionRuleMovedUp(rule: TransactionRule) {
    setWorkingTransactionIdentificationRules(
      workingTransactionIdentificationRules.up(rule).clone()
    );
  }

  function onTransactionRuleMovedDown(rule: TransactionRule) {
    setWorkingTransactionIdentificationRules(
      workingTransactionIdentificationRules.down(rule).clone()
    );
  }

  function onHandlingRuleMovedUp(rule: HandlingRule) {
    setWorkingHandlingRules(workingHandlingRules.up(rule).clone());
  }

  function onHandlingRuleMovedDown(rule: HandlingRule) {
    setWorkingHandlingRules(workingHandlingRules.down(rule).clone());
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography fontWeight={'bolder'}>Required connections:</Typography>
      </Grid>
      <Grid item xs={12}>
        <Controller
          control={form.control}
          render={({ field }) => (
            <AppRequirementConfiguration
              title="ERP"
              value={field.value}
              edit={edit}
              options={erpConnections}
              onChange={field.onChange}
            />
          )}
          name={'connections.erp'}
        />
        <Controller
          control={form.control}
          render={({ field }) => (
            <AppRequirementConfiguration
              title="Bank"
              value={field.value}
              edit={edit}
              options={bankConnections}
              onChange={field.onChange}
            />
          )}
          name={'connections.bank'}
        />
      </Grid>
      <Grid item xs={12}>
        <Collapsible title={'General'} defaultExpanded={false}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Stack spacing={2}>
                <HookFormTextInput
                  name="account.bic"
                  form={form}
                  label="BIC"
                  disabled={!edit}
                  sx={{ width: '150px' }}
                />
                <HookFormTextInput
                  name="account.iban"
                  form={form}
                  label="IBAN"
                  disabled={!edit}
                  sx={{ width: '300px' }}
                />
                <HookFormTextInput
                  name="general.dateFormat"
                  form={form}
                  label="Date format used in Netsuite"
                  disabled={!edit}
                  sx={{ width: '300px' }}
                />
                <HookFormTextInput
                  name="general.roundingTolerance"
                  form={form}
                  label="Rounding error -tolerance"
                  disabled={!edit}
                  type={'number'}
                  sx={{ width: '300px' }}
                />
              </Stack>
            </Grid>
          </Grid>
        </Collapsible>
        <Collapsible title={'Transactions'} defaultExpanded={false}>
          <Grid container spacing={2}>
            <Grid item xs={6} paddingRight={2}>
              <Stack spacing={2}>
                <Typography>
                  Define field names used to store transaction reference
                </Typography>
                <HookFormTextInput
                  name="transactions.referenceFieldName"
                  form={form}
                  label="Primary property name for reference number"
                  disabled={!edit}
                  fullWidth
                />
                <HookFormTextInput
                  name="transactions.referenceFieldName2"
                  form={form}
                  label="Secondary (optional) property name for reference number"
                  disabled={!edit}
                  fullWidth
                />
                <HookFormTextInput
                  name="transactions.referenceFieldName3"
                  form={form}
                  label="Tertiary (optional) property name for reference number"
                  disabled={!edit}
                  fullWidth
                />
                <HookFormTextInput
                  name="transactions.referenceFieldName4"
                  form={form}
                  label="Quaternary (optional) property name for reference number"
                  disabled={!edit}
                  fullWidth
                />
                <HookFormTextInput
                  name="transactions.referenceFieldName5"
                  form={form}
                  label="Quinary (optional) property name for reference number"
                  disabled={!edit}
                  fullWidth
                />
              </Stack>
            </Grid>
            <Grid item xs={6}>
              <Stack spacing={2}>
                <Typography>
                  Define field names used to store transaction number
                </Typography>
                <HookFormTextInput
                  name="transactions.numberFieldName"
                  form={form}
                  label="Primary property name for transaction number"
                  disabled={!edit}
                  fullWidth
                />
                <HookFormTextInput
                  name="transactions.numberFieldName2"
                  form={form}
                  label="Secondary (optional) property name for transaction number"
                  disabled={!edit}
                  fullWidth
                />
                <HookFormTextInput
                  name="transactions.numberFieldName3"
                  form={form}
                  label="Tertiary (optional) property name for transaction number"
                  disabled={!edit}
                  fullWidth
                />
                <HookFormTextInput
                  name="transactions.numberFieldName4"
                  form={form}
                  label="Quaternary (optional) property name for transaction number"
                  disabled={!edit}
                  fullWidth
                />
                <HookFormTextInput
                  name="transactions.numberFieldName5"
                  form={form}
                  label="Quinary (optional) property name for transaction number"
                  disabled={!edit}
                  fullWidth
                />
              </Stack>
            </Grid>
          </Grid>
        </Collapsible>
        <Collapsible
          title={'Entity identification rules'}
          defaultExpanded={false}
        >
          <RuleList
            rules={workingEntityIdentificationRules}
            onChange={onEntityIdentificationRuleChange}
            onMoveUp={onEntityRuleMovedUp}
            onMoveDown={onEntityRuleMovedDown}
            edit={edit}
          />
        </Collapsible>
        <Collapsible
          title={'Transaction identification rules'}
          defaultExpanded={false}
        >
          <RuleList
            rules={workingTransactionIdentificationRules}
            onChange={onTransactionIdentificationRuleChange}
            onMoveUp={onTransactionRuleMovedUp}
            onMoveDown={onTransactionRuleMovedDown}
            edit={edit}
          />
        </Collapsible>
        <Collapsible
          title={'Transaction handling rules'}
          defaultExpanded={false}
        >
          <RuleList
            rules={workingHandlingRules}
            configurationData={{
              erpAccounts: {
                result: listAccountResult?.result ?? [],
                pending: listAccountPending,
                error: listAccountError,
              },
            }}
            onChange={onHandlingRuleChange}
            onMoveUp={onHandlingRuleMovedUp}
            onMoveDown={onHandlingRuleMovedDown}
            edit={edit}
          />
        </Collapsible>
      </Grid>
      <Grid item xs={12}>
        <LoadingButton
          loading={pending}
          disabled={!edit}
          onClick={form.handleSubmit(onSubmit)}
        >
          Save
        </LoadingButton>
      </Grid>
    </Grid>
  );
};

export default ConfigureBankTransactionSubscription;
