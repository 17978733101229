import React, { JSX } from 'react';

import { Route, Routes } from 'react-router-dom';
import AppListView from './AppListView';
import AppVersionManagementTool from '../../../components/app/AppVersionManagementTool';
import { SystemglueAppName } from '@apus/common-lib/api/interface/common';

const AppManagementView = (): JSX.Element => {
  return (
    <Routes>
      <Route path="/" element={<AppListView />} />
      <Route
        path={`/${SystemglueAppName.PAYMENT_INTEGRATION_APP}`}
        element={
          <AppVersionManagementTool
            appName={SystemglueAppName.PAYMENT_INTEGRATION_APP}
          />
        }
      />
      <Route
        path={`/${SystemglueAppName.BANK_TRANSACTION_INTEGRATION_APP}`}
        element={
          <AppVersionManagementTool
            appName={SystemglueAppName.BANK_TRANSACTION_INTEGRATION_APP}
          />
        }
      />
      <Route
        path={`/${SystemglueAppName.E_INVOICE_INTEGRATION_APP}`}
        element={
          <AppVersionManagementTool
            appName={SystemglueAppName.E_INVOICE_INTEGRATION_APP}
          />
        }
      />
    </Routes>
  );
};

export default AppManagementView;
