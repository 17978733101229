import { SupportedModule } from '../../../integrations/src/interface';

export const bankConnections: SupportedModule[] = ['pagero'];
export const erpConnections: SupportedModule[] = ['netsuite'];

// TODO: schema generation doesn't (yet) handle the typeof -solution
type SupportedBank = 'pagero'; //(typeof bankConnections)[number];
type SupportedErp = 'netsuite'; //(typeof erpConnections)[number];

export interface EInvoiceIntegrationAppConfiguration {
  /**
   * @title System connections
   */
  connections: {
    /**
     * @title Used ERP
     */
    erp: SupportedErp;
    /**
     * @title Used bank
     */
    bank: SupportedBank;
  };
  /**
   * @title ERP subsidiary details
   */
  subsidiary: {
    /**
     * @title Identifier for the subsidiary
     */
    // TODO: change to array
    subsidiaryId: string;
    /**
     * @title Subsidiary name
     */
    subsidiaryName?: string;
  };
}
