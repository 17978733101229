import React, { JSX } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';

import { CustomContentMediaType } from '@apus/common-lib/api/interface/files';
import { ConfigurationSchema } from '@apus/common-lib/integrations/src/interface';
import { SourceObject } from '@apus/common-lib/json-data-mapper/src/interface';
import {
  typescriptFunctionFromString,
  typescriptFunctionToString,
} from '@apus/common-lib/runtime-typescript/src/function-utils';
import { JSONSchema7 } from 'json-schema';

import DefineJsonHandler from './editor/json/DefineJsonHandler';
import LiquidJsTemplateEditor from './editor/liquidjs/DefineLiquidJsTemplateHandler';
import DefineTypescriptHandler from './editor/typescript/DefineTypescriptHandler';
import InputFromSchema from './InputFromSchema';

type Props<T = SourceObject> = {
  readOnly?: boolean;
  form: UseFormReturn<any>;
  configurationSchema?: ConfigurationSchema;
  inputSchema?: JSONSchema7;
  outputSchema?: JSONSchema7;
  propertyPath?: string;
  onChange?: (configuration: T) => void;
};

const ConfigurationSchemaForm = ({
  readOnly = false,
  configurationSchema,
  form,
  inputSchema,
  outputSchema,
  propertyPath,
  onChange,
}: Props): JSX.Element => {
  if (configurationSchema === undefined) return <></>;

  function configurationChanged() {
    if (onChange !== undefined)
      onChange(
        propertyPath !== undefined
          ? form.getValues(propertyPath)
          : form.getValues()
      );
  }

  return (
    <>
      {Object.keys(configurationSchema.properties).map((prop, idx) => {
        const key = `${prop}-${idx}`;
        const isRequired =
          configurationSchema.required?.includes(prop) ?? false;
        const schema = configurationSchema?.properties[prop];
        if (schema === undefined) return <></>;

        const name =
          propertyPath !== undefined ? `${propertyPath}.${prop}` : prop;

        if (schema.contentMediaType !== undefined) {
          if (
            schema.contentMediaType ===
            CustomContentMediaType.TypescriptFunction
          ) {
            return (
              <Controller
                key={key}
                render={({ field }) => (
                  <DefineTypescriptHandler
                    allowUndefined={!isRequired}
                    readOnly={readOnly}
                    title={schema?.title}
                    helperText={schema?.description}
                    value={
                      field.value !== undefined
                        ? typescriptFunctionFromString(field.value)
                        : undefined
                    }
                    functionName={'run'}
                    onChange={value => {
                      field.onChange(typescriptFunctionToString(value));
                      configurationChanged();
                    }}
                    inputSchema={inputSchema}
                    outputSchema={outputSchema}
                  />
                )}
                name={name}
                control={form.control}
              />
            );
          }

          if (
            schema.contentMediaType === CustomContentMediaType.LiquidTemplate
          ) {
            return (
              <Controller
                key={key}
                render={({ field }) => (
                  <LiquidJsTemplateEditor
                    allowUndefined={!isRequired}
                    readOnly={readOnly}
                    title={schema?.title}
                    helperText={schema?.description}
                    value={field.value}
                    onChange={value => {
                      field.onChange(value);
                      configurationChanged();
                    }}
                  />
                )}
                name={name}
                control={form.control}
              />
            );
          }

          if (schema.contentMediaType === 'application/json') {
            return (
              <Controller
                key={key}
                render={({ field }) => (
                  <DefineJsonHandler
                    allowUndefined={!isRequired}
                    readOnly={readOnly}
                    title={schema?.title}
                    helperText={schema?.description}
                    value={
                      field.value !== undefined ? JSON.parse(field.value) : {}
                    }
                    onChange={value => {
                      field.onChange(JSON.stringify(value));
                      configurationChanged();
                    }}
                  />
                )}
                name={name}
                control={form.control}
              />
            );
          }
        }

        return (
          <InputFromSchema
            key={key}
            name={propertyPath !== undefined ? `${propertyPath}.${prop}` : prop}
            schema={schema}
            form={form}
            disabled={readOnly}
            onChange={() => {
              configurationChanged();
            }}
          />
        );
      })}
    </>
  );
};

export default ConfigurationSchemaForm;
