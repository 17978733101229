export const bankTransaction = {
  type: 'object',
  properties: {
    bankPaymentId: {
      type: 'string',
      title: 'Unique identifier for the payment - provided by the bank',
    },
    account: {
      type: 'object',
      properties: {
        iban: {
          type: 'string',
          title: 'Transaction bank account number',
          description: 'Bank account number in IBAN format',
        },
        bic: {
          type: 'string',
          title: 'Transaction bank identifier',
          description: 'Bank identifier in BIC format',
        },
      },
      required: ['iban', 'bic'],
      title: 'The bank account',
      description: 'The bank account where this transaction happened',
    },
    entityName: {
      type: 'string',
      title: 'Transaction customer',
      description:
        'The name of the external customer decided by Systemglue - this is either the debtor or the creditor depending on transaction type',
    },
    debtorName: {
      type: 'string',
      title: 'The debtor name defined by the bank',
      description: 'This is the original debtor name from the transaction data',
    },
    creditorName: {
      type: 'string',
      title: 'The creditor name defined by the bank',
      description:
        'This is the original creditor name from the transaction data',
    },
    originalMessage: {
      type: 'string',
      title: 'Transaction message',
      description:
        'This is the original message provided by the bank for this transaction',
    },
    message: {
      type: 'string',
      title: 'Pre-processed transaction message',
      description:
        'This is the pre-processed message from the transaction data. Sometimes banks provide the data in processed format, so we need to make it comparable',
    },
    originalReference: {
      type: 'string',
      title: 'Transaction national reference',
      description: 'This is the original reference from the transaction data',
    },
    reference: {
      type: 'string',
      title: 'Pre-processed transaction national reference',
      description:
        'This is the pre-processed reference from the transaction data. Sometimes banks provide the data in processed format, so we need to make it comparable',
    },
    originalRfReference: {
      type: 'string',
      title: 'Transaction RF reference',
      description: 'This is the original rfReference from the transaction data',
    },
    rfReference: {
      type: ['string', 'null'],
      title: 'Pre-processed transaction RF reference',
      description:
        'This is the pre-processed rfReference from the transaction data. Sometimes banks provide the data in processed format, so we need to make it comparable',
    },
    paymentISODate: {
      type: 'string',
      title: 'Transaction date',
      description:
        'This is the original payment date from the transaction data converted into ISO format',
    },
    currencyCode: {
      type: 'string',
      title: 'Transaction currency',
      description:
        'This is the original currency code as an ISO currency code from the transaction data',
    },
    originalAmount: {
      type: 'number',
      title: 'Transaction amount in originating currency',
      description:
        'Amount transferred in the transaction. Debit transactions are marked with a minus sign and credit transactions are positive',
    },
    amount: {
      type: 'number',
      title: 'Transaction amount in euros',
      description:
        "Original amount converted into euros - if this isn't provided by the bank, then it is automatically converted by Systemglue using the currency conversion rate queried from ERP",
    },
    transactionType: {
      type: 'string',
      enum: ['debit', 'credit'],
      title: 'Transaction type',
      description:
        'In debit transactions the Systemglue customer has made a payment to the resolved entity, and in credit transactions Systemglue customer receives a payment',
    },
  },
  required: [
    'bankPaymentId',
    'account',
    'entityName',
    'debtorName',
    'creditorName',
    'paymentISODate',
    'currencyCode',
    'originalAmount',
    'amount',
    'transactionType',
  ],
  $schema: 'http://json-schema.org/draft-07/schema#',
};

export const entityIdentificationRuleInput = {
  type: 'object',
  properties: {
    bankTransaction: {
      type: 'object',
      properties: {
        bankPaymentId: {
          type: 'string',
          title: 'Unique identifier for the payment - provided by the bank',
        },
        account: {
          type: 'object',
          properties: {
            iban: {
              type: 'string',
              title: 'Transaction bank account number',
              description: 'Bank account number in IBAN format',
            },
            bic: {
              type: 'string',
              title: 'Transaction bank identifier',
              description: 'Bank identifier in BIC format',
            },
          },
          required: ['iban', 'bic'],
          title: 'The bank account',
          description: 'The bank account where this transaction happened',
        },
        entityName: {
          type: 'string',
          title: 'Transaction customer',
          description:
            'The name of the external customer decided by Systemglue - this is either the debtor or the creditor depending on transaction type',
        },
        debtorName: {
          type: 'string',
          title: 'The debtor name defined by the bank',
          description:
            'This is the original debtor name from the transaction data',
        },
        creditorName: {
          type: 'string',
          title: 'The creditor name defined by the bank',
          description:
            'This is the original creditor name from the transaction data',
        },
        originalMessage: {
          type: 'string',
          title: 'Transaction message',
          description:
            'This is the original message provided by the bank for this transaction',
        },
        message: {
          type: 'string',
          title: 'Pre-processed transaction message',
          description:
            'This is the pre-processed message from the transaction data. Sometimes banks provide the data in processed format, so we need to make it comparable',
        },
        originalReference: {
          type: 'string',
          title: 'Transaction national reference',
          description:
            'This is the original reference from the transaction data',
        },
        reference: {
          type: 'string',
          title: 'Pre-processed transaction national reference',
          description:
            'This is the pre-processed reference from the transaction data. Sometimes banks provide the data in processed format, so we need to make it comparable',
        },
        originalRfReference: {
          type: 'string',
          title: 'Transaction RF reference',
          description:
            'This is the original rfReference from the transaction data',
        },
        rfReference: {
          type: ['string', 'null'],
          title: 'Pre-processed transaction RF reference',
          description:
            'This is the pre-processed rfReference from the transaction data. Sometimes banks provide the data in processed format, so we need to make it comparable',
        },
        paymentISODate: {
          type: 'string',
          title: 'Transaction date',
          description:
            'This is the original payment date from the transaction data converted into ISO format',
        },
        currencyCode: {
          type: 'string',
          title: 'Transaction currency',
          description:
            'This is the original currency code as an ISO currency code from the transaction data',
        },
        originalAmount: {
          type: 'number',
          title: 'Transaction amount in originating currency',
          description:
            'Amount transferred in the transaction. Debit transactions are marked with a minus sign and credit transactions are positive',
        },
        amount: {
          type: 'number',
          title: 'Transaction amount in euros',
          description:
            "Original amount converted into euros - if this isn't provided by the bank, then it is automatically converted by Systemglue using the currency conversion rate queried from ERP",
        },
        transactionType: {
          type: 'string',
          enum: ['debit', 'credit'],
          title: 'Transaction type',
          description:
            'In debit transactions the Systemglue customer has made a payment to the resolved entity, and in credit transactions Systemglue customer receives a payment',
        },
      },
      required: [
        'bankPaymentId',
        'account',
        'entityName',
        'debtorName',
        'creditorName',
        'paymentISODate',
        'currencyCode',
        'originalAmount',
        'amount',
        'transactionType',
      ],
    },
    erpEntity: {
      type: 'object',
      properties: {
        entityName: {
          type: 'string',
          title: 'ERP entity name',
          description: 'Name of the entity as defined in ERP',
        },
        entityId: {
          type: 'string',
          title: 'ERP entity internal id',
          description: 'The internal id for the entity in ERP',
        },
        entityType: {
          type: 'string',
          enum: ['vendor', 'customer'],
          title: 'ERP entity type',
          description: 'The type of the entity',
        },
        invoiceGroupingEnabled: {
          type: 'boolean',
          title: 'ERP entity uses invoice grouping',
          description: 'Does entity have invoice grouping enabled?',
        },
      },
      required: ['entityName', 'entityId', 'entityType'],
    },
  },
  required: ['bankTransaction'],
  $schema: 'http://json-schema.org/draft-07/schema#',
};

export const transactionIdentificationRuleInput = {
  type: 'object',
  properties: {
    bankTransaction: {
      type: 'object',
      properties: {
        bankPaymentId: {
          type: 'string',
          title: 'Unique identifier for the payment - provided by the bank',
        },
        account: {
          type: 'object',
          properties: {
            iban: {
              type: 'string',
              title: 'Transaction bank account number',
              description: 'Bank account number in IBAN format',
            },
            bic: {
              type: 'string',
              title: 'Transaction bank identifier',
              description: 'Bank identifier in BIC format',
            },
          },
          required: ['iban', 'bic'],
          title: 'The bank account',
          description: 'The bank account where this transaction happened',
        },
        entityName: {
          type: 'string',
          title: 'Transaction customer',
          description:
            'The name of the external customer decided by Systemglue - this is either the debtor or the creditor depending on transaction type',
        },
        debtorName: {
          type: 'string',
          title: 'The debtor name defined by the bank',
          description:
            'This is the original debtor name from the transaction data',
        },
        creditorName: {
          type: 'string',
          title: 'The creditor name defined by the bank',
          description:
            'This is the original creditor name from the transaction data',
        },
        originalMessage: {
          type: 'string',
          title: 'Transaction message',
          description:
            'This is the original message provided by the bank for this transaction',
        },
        message: {
          type: 'string',
          title: 'Pre-processed transaction message',
          description:
            'This is the pre-processed message from the transaction data. Sometimes banks provide the data in processed format, so we need to make it comparable',
        },
        originalReference: {
          type: 'string',
          title: 'Transaction national reference',
          description:
            'This is the original reference from the transaction data',
        },
        reference: {
          type: 'string',
          title: 'Pre-processed transaction national reference',
          description:
            'This is the pre-processed reference from the transaction data. Sometimes banks provide the data in processed format, so we need to make it comparable',
        },
        originalRfReference: {
          type: 'string',
          title: 'Transaction RF reference',
          description:
            'This is the original rfReference from the transaction data',
        },
        rfReference: {
          type: ['string', 'null'],
          title: 'Pre-processed transaction RF reference',
          description:
            'This is the pre-processed rfReference from the transaction data. Sometimes banks provide the data in processed format, so we need to make it comparable',
        },
        paymentISODate: {
          type: 'string',
          title: 'Transaction date',
          description:
            'This is the original payment date from the transaction data converted into ISO format',
        },
        currencyCode: {
          type: 'string',
          title: 'Transaction currency',
          description:
            'This is the original currency code as an ISO currency code from the transaction data',
        },
        originalAmount: {
          type: 'number',
          title: 'Transaction amount in originating currency',
          description:
            'Amount transferred in the transaction. Debit transactions are marked with a minus sign and credit transactions are positive',
        },
        amount: {
          type: 'number',
          title: 'Transaction amount in euros',
          description:
            "Original amount converted into euros - if this isn't provided by the bank, then it is automatically converted by Systemglue using the currency conversion rate queried from ERP",
        },
        transactionType: {
          type: 'string',
          enum: ['debit', 'credit'],
          title: 'Transaction type',
          description:
            'In debit transactions the Systemglue customer has made a payment to the resolved entity, and in credit transactions Systemglue customer receives a payment',
        },
      },
      required: [
        'bankPaymentId',
        'account',
        'entityName',
        'debtorName',
        'creditorName',
        'paymentISODate',
        'currencyCode',
        'originalAmount',
        'amount',
        'transactionType',
      ],
    },
    erpEntity: {
      type: 'object',
      properties: {
        entityName: {
          type: 'string',
          title: 'ERP entity name',
          description: 'Name of the entity as defined in ERP',
        },
        entityId: {
          type: 'string',
          title: 'ERP entity internal id',
          description: 'The internal id for the entity in ERP',
        },
        entityType: {
          type: 'string',
          enum: ['vendor', 'customer'],
          title: 'ERP entity type',
          description: 'The type of the entity',
        },
        invoiceGroupingEnabled: {
          type: 'boolean',
          title: 'ERP entity uses invoice grouping',
          description: 'Does entity have invoice grouping enabled?',
        },
      },
      required: ['entityName', 'entityId', 'entityType'],
    },
    erpTransaction: {
      type: 'object',
      properties: {
        transactionType: {
          type: 'string',
          enum: ['vendor-bill', 'customer-invoice'],
          title: 'ERP transaction type',
          description: 'The type of the transaction in ERP',
        },
        entityId: {
          type: 'string',
          title: 'ERP transaction entity internal id',
          description: 'The internal id for the transaction owner in ERP',
        },
        reference: {
          type: 'string',
          title: 'ERP transaction reference',
          description:
            'The attribute in which references are stored in ERP can vary depending on the transaction type. Supported attribute names are defined in priority order in the application configuration and resolved reference is the one found first when querying transaction data.',
        },
        number: {
          type: 'string',
          title: 'ERP transaction number',
          description:
            'The attribute in which transaction numbers are stored in ERP can vary depending on the transaction type. Supported attribute names are defined in priority order in the application configuration and resolved number is the one found first when querying transaction data.',
        },
        createdISODate: {
          type: 'string',
          title: 'ERP transaction creation date',
          description: 'Transaction creation date in ISO format',
        },
        dueISODate: {
          type: 'string',
          title: 'ERP transaction due date',
          description: 'Transaction due date in ISO format',
        },
        internalId: {
          type: 'string',
          title: 'ERP transaction internal id',
          description: 'The internal id for the transaction in ERP',
        },
        amountTotal: {
          type: 'number',
          title: 'ERP transaction total original amount',
          description: 'The total amount for the transaction',
        },
        amountRemaining: {
          type: 'number',
          title: 'ERP transaction amount remaining',
          description: 'The amount currently open for the transaction',
        },
        currencyCode: {
          type: 'string',
          title: 'ERP transaction currency',
          description: 'The currency used in transaction in ISO format',
        },
      },
      required: [
        'transactionType',
        'entityId',
        'createdISODate',
        'dueISODate',
        'internalId',
        'amountTotal',
        'amountRemaining',
        'currencyCode',
      ],
    },
  },
  required: ['bankTransaction', 'erpTransaction'],
  $schema: 'http://json-schema.org/draft-07/schema#',
};

export const listBankTransactionsInput = {
  type: 'object',
  properties: {
    from: {
      type: 'string',
      title: 'From date time (ISO string) - default epoch',
    },
    to: {
      type: 'string',
      title: 'To date time (ISO string) - default current time',
    },
  },
  title: 'List bank transactions input data model',
  $schema: 'http://json-schema.org/draft-07/schema#',
};

export const listBankTransactionsOutput = {
  type: 'object',
  properties: {
    bankTransactions: {
      type: 'array',
      items: {
        type: 'object',
        properties: {
          bankPaymentId: {
            type: 'string',
            title: 'Unique identifier for the payment - provided by the bank',
          },
          account: {
            type: 'object',
            properties: {
              iban: {
                type: 'string',
                title: 'Transaction bank account number',
                description: 'Bank account number in IBAN format',
              },
              bic: {
                type: 'string',
                title: 'Transaction bank identifier',
                description: 'Bank identifier in BIC format',
              },
            },
            required: ['iban', 'bic'],
            title: 'The bank account',
            description: 'The bank account where this transaction happened',
          },
          entityName: {
            type: 'string',
            title: 'Transaction customer',
            description:
              'The name of the external customer decided by Systemglue - this is either the debtor or the creditor depending on transaction type',
          },
          debtorName: {
            type: 'string',
            title: 'The debtor name defined by the bank',
            description:
              'This is the original debtor name from the transaction data',
          },
          creditorName: {
            type: 'string',
            title: 'The creditor name defined by the bank',
            description:
              'This is the original creditor name from the transaction data',
          },
          originalMessage: {
            type: 'string',
            title: 'Transaction message',
            description:
              'This is the original message provided by the bank for this transaction',
          },
          message: {
            type: 'string',
            title: 'Pre-processed transaction message',
            description:
              'This is the pre-processed message from the transaction data. Sometimes banks provide the data in processed format, so we need to make it comparable',
          },
          originalReference: {
            type: 'string',
            title: 'Transaction national reference',
            description:
              'This is the original reference from the transaction data',
          },
          reference: {
            type: 'string',
            title: 'Pre-processed transaction national reference',
            description:
              'This is the pre-processed reference from the transaction data. Sometimes banks provide the data in processed format, so we need to make it comparable',
          },
          originalRfReference: {
            type: 'string',
            title: 'Transaction RF reference',
            description:
              'This is the original rfReference from the transaction data',
          },
          rfReference: {
            type: ['string', 'null'],
            title: 'Pre-processed transaction RF reference',
            description:
              'This is the pre-processed rfReference from the transaction data. Sometimes banks provide the data in processed format, so we need to make it comparable',
          },
          paymentISODate: {
            type: 'string',
            title: 'Transaction date',
            description:
              'This is the original payment date from the transaction data converted into ISO format',
          },
          currencyCode: {
            type: 'string',
            title: 'Transaction currency',
            description:
              'This is the original currency code as an ISO currency code from the transaction data',
          },
          originalAmount: {
            type: 'number',
            title: 'Transaction amount in originating currency',
            description:
              'Amount transferred in the transaction. Debit transactions are marked with a minus sign and credit transactions are positive',
          },
          amount: {
            type: 'number',
            title: 'Transaction amount in euros',
            description:
              "Original amount converted into euros - if this isn't provided by the bank, then it is automatically converted by Systemglue using the currency conversion rate queried from ERP",
          },
          transactionType: {
            type: 'string',
            enum: ['debit', 'credit'],
            title: 'Transaction type',
            description:
              'In debit transactions the Systemglue customer has made a payment to the resolved entity, and in credit transactions Systemglue customer receives a payment',
          },
        },
        required: [
          'bankPaymentId',
          'account',
          'entityName',
          'debtorName',
          'creditorName',
          'paymentISODate',
          'currencyCode',
          'originalAmount',
          'amount',
          'transactionType',
        ],
      },
      title: 'Bank transactions',
    },
  },
  required: ['bankTransactions'],
  title: 'List bank transactions output data model',
  $schema: 'http://json-schema.org/draft-07/schema#',
};

export const queryErpDataInput = {
  type: 'object',
  properties: {
    bankTransaction: {
      type: 'object',
      properties: {
        bankPaymentId: {
          type: 'string',
          title: 'Unique identifier for the payment - provided by the bank',
        },
        account: {
          type: 'object',
          properties: {
            iban: {
              type: 'string',
              title: 'Transaction bank account number',
              description: 'Bank account number in IBAN format',
            },
            bic: {
              type: 'string',
              title: 'Transaction bank identifier',
              description: 'Bank identifier in BIC format',
            },
          },
          required: ['iban', 'bic'],
          title: 'The bank account',
          description: 'The bank account where this transaction happened',
        },
        entityName: {
          type: 'string',
          title: 'Transaction customer',
          description:
            'The name of the external customer decided by Systemglue - this is either the debtor or the creditor depending on transaction type',
        },
        debtorName: {
          type: 'string',
          title: 'The debtor name defined by the bank',
          description:
            'This is the original debtor name from the transaction data',
        },
        creditorName: {
          type: 'string',
          title: 'The creditor name defined by the bank',
          description:
            'This is the original creditor name from the transaction data',
        },
        originalMessage: {
          type: 'string',
          title: 'Transaction message',
          description:
            'This is the original message provided by the bank for this transaction',
        },
        message: {
          type: 'string',
          title: 'Pre-processed transaction message',
          description:
            'This is the pre-processed message from the transaction data. Sometimes banks provide the data in processed format, so we need to make it comparable',
        },
        originalReference: {
          type: 'string',
          title: 'Transaction national reference',
          description:
            'This is the original reference from the transaction data',
        },
        reference: {
          type: 'string',
          title: 'Pre-processed transaction national reference',
          description:
            'This is the pre-processed reference from the transaction data. Sometimes banks provide the data in processed format, so we need to make it comparable',
        },
        originalRfReference: {
          type: 'string',
          title: 'Transaction RF reference',
          description:
            'This is the original rfReference from the transaction data',
        },
        rfReference: {
          type: ['string', 'null'],
          title: 'Pre-processed transaction RF reference',
          description:
            'This is the pre-processed rfReference from the transaction data. Sometimes banks provide the data in processed format, so we need to make it comparable',
        },
        paymentISODate: {
          type: 'string',
          title: 'Transaction date',
          description:
            'This is the original payment date from the transaction data converted into ISO format',
        },
        currencyCode: {
          type: 'string',
          title: 'Transaction currency',
          description:
            'This is the original currency code as an ISO currency code from the transaction data',
        },
        originalAmount: {
          type: 'number',
          title: 'Transaction amount in originating currency',
          description:
            'Amount transferred in the transaction. Debit transactions are marked with a minus sign and credit transactions are positive',
        },
        amount: {
          type: 'number',
          title: 'Transaction amount in euros',
          description:
            "Original amount converted into euros - if this isn't provided by the bank, then it is automatically converted by Systemglue using the currency conversion rate queried from ERP",
        },
        transactionType: {
          type: 'string',
          enum: ['debit', 'credit'],
          title: 'Transaction type',
          description:
            'In debit transactions the Systemglue customer has made a payment to the resolved entity, and in credit transactions Systemglue customer receives a payment',
        },
      },
      required: [
        'bankPaymentId',
        'account',
        'entityName',
        'debtorName',
        'creditorName',
        'paymentISODate',
        'currencyCode',
        'originalAmount',
        'amount',
        'transactionType',
      ],
    },
  },
  required: ['bankTransaction'],
  title: 'Query erp data -input data model',
  description: 'Return relevant entities and transactions from erp',
  $schema: 'http://json-schema.org/draft-07/schema#',
};

export const queryErpDataOutput = {
  type: 'object',
  properties: {
    erpEntities: {
      type: 'array',
      items: {
        type: 'object',
        properties: {
          entityName: {
            type: 'string',
            title: 'ERP entity name',
            description: 'Name of the entity as defined in ERP',
          },
          entityId: {
            type: 'string',
            title: 'ERP entity internal id',
            description: 'The internal id for the entity in ERP',
          },
          entityType: {
            type: 'string',
            enum: ['vendor', 'customer'],
            title: 'ERP entity type',
            description: 'The type of the entity',
          },
          invoiceGroupingEnabled: {
            type: 'boolean',
            title: 'ERP entity uses invoice grouping',
            description: 'Does entity have invoice grouping enabled?',
          },
        },
        required: ['entityName', 'entityId', 'entityType'],
      },
    },
    erpTransactions: {
      type: 'array',
      items: {
        type: 'object',
        properties: {
          transactionType: {
            type: 'string',
            enum: ['vendor-bill', 'customer-invoice'],
            title: 'ERP transaction type',
            description: 'The type of the transaction in ERP',
          },
          entityId: {
            type: 'string',
            title: 'ERP transaction entity internal id',
            description: 'The internal id for the transaction owner in ERP',
          },
          reference: {
            type: 'string',
            title: 'ERP transaction reference',
            description:
              'The attribute in which references are stored in ERP can vary depending on the transaction type. Supported attribute names are defined in priority order in the application configuration and resolved reference is the one found first when querying transaction data.',
          },
          number: {
            type: 'string',
            title: 'ERP transaction number',
            description:
              'The attribute in which transaction numbers are stored in ERP can vary depending on the transaction type. Supported attribute names are defined in priority order in the application configuration and resolved number is the one found first when querying transaction data.',
          },
          createdISODate: {
            type: 'string',
            title: 'ERP transaction creation date',
            description: 'Transaction creation date in ISO format',
          },
          dueISODate: {
            type: 'string',
            title: 'ERP transaction due date',
            description: 'Transaction due date in ISO format',
          },
          internalId: {
            type: 'string',
            title: 'ERP transaction internal id',
            description: 'The internal id for the transaction in ERP',
          },
          amountTotal: {
            type: 'number',
            title: 'ERP transaction total original amount',
            description: 'The total amount for the transaction',
          },
          amountRemaining: {
            type: 'number',
            title: 'ERP transaction amount remaining',
            description: 'The amount currently open for the transaction',
          },
          currencyCode: {
            type: 'string',
            title: 'ERP transaction currency',
            description: 'The currency used in transaction in ISO format',
          },
        },
        required: [
          'transactionType',
          'entityId',
          'createdISODate',
          'dueISODate',
          'internalId',
          'amountTotal',
          'amountRemaining',
          'currencyCode',
        ],
      },
    },
    bankConfiguration: {
      type: 'object',
      properties: {
        id: { type: 'string', title: 'Bank configuration id' },
        name: { type: 'string', title: 'Bank configuration name' },
        iban: {
          type: 'string',
          title: 'Bank account number',
          description: 'Bank account number in IBAN format',
        },
        bic: {
          type: 'string',
          title: 'Bank identifier',
          description: 'Bank identifier in BIC format',
        },
        glAccountInternalId: {
          type: 'string',
          title: 'COA account for the bank account',
        },
      },
      required: ['id', 'name', 'iban', 'bic', 'glAccountInternalId'],
      title: 'The bank configuration',
      description:
        'The bank configuration matching the account on the bank transaction',
    },
  },
  required: ['erpEntities', 'erpTransactions', 'bankConfiguration'],
  title: 'Query erp data -output data model',
  description: 'Return relevant entities and transactions from erp',
  $schema: 'http://json-schema.org/draft-07/schema#',
};

export const resolveTransactionDataInput = {
  type: 'object',
  properties: {
    bankTransaction: {
      type: 'object',
      properties: {
        bankPaymentId: {
          type: 'string',
          title: 'Unique identifier for the payment - provided by the bank',
        },
        account: {
          type: 'object',
          properties: {
            iban: {
              type: 'string',
              title: 'Transaction bank account number',
              description: 'Bank account number in IBAN format',
            },
            bic: {
              type: 'string',
              title: 'Transaction bank identifier',
              description: 'Bank identifier in BIC format',
            },
          },
          required: ['iban', 'bic'],
          title: 'The bank account',
          description: 'The bank account where this transaction happened',
        },
        entityName: {
          type: 'string',
          title: 'Transaction customer',
          description:
            'The name of the external customer decided by Systemglue - this is either the debtor or the creditor depending on transaction type',
        },
        debtorName: {
          type: 'string',
          title: 'The debtor name defined by the bank',
          description:
            'This is the original debtor name from the transaction data',
        },
        creditorName: {
          type: 'string',
          title: 'The creditor name defined by the bank',
          description:
            'This is the original creditor name from the transaction data',
        },
        originalMessage: {
          type: 'string',
          title: 'Transaction message',
          description:
            'This is the original message provided by the bank for this transaction',
        },
        message: {
          type: 'string',
          title: 'Pre-processed transaction message',
          description:
            'This is the pre-processed message from the transaction data. Sometimes banks provide the data in processed format, so we need to make it comparable',
        },
        originalReference: {
          type: 'string',
          title: 'Transaction national reference',
          description:
            'This is the original reference from the transaction data',
        },
        reference: {
          type: 'string',
          title: 'Pre-processed transaction national reference',
          description:
            'This is the pre-processed reference from the transaction data. Sometimes banks provide the data in processed format, so we need to make it comparable',
        },
        originalRfReference: {
          type: 'string',
          title: 'Transaction RF reference',
          description:
            'This is the original rfReference from the transaction data',
        },
        rfReference: {
          type: ['string', 'null'],
          title: 'Pre-processed transaction RF reference',
          description:
            'This is the pre-processed rfReference from the transaction data. Sometimes banks provide the data in processed format, so we need to make it comparable',
        },
        paymentISODate: {
          type: 'string',
          title: 'Transaction date',
          description:
            'This is the original payment date from the transaction data converted into ISO format',
        },
        currencyCode: {
          type: 'string',
          title: 'Transaction currency',
          description:
            'This is the original currency code as an ISO currency code from the transaction data',
        },
        originalAmount: {
          type: 'number',
          title: 'Transaction amount in originating currency',
          description:
            'Amount transferred in the transaction. Debit transactions are marked with a minus sign and credit transactions are positive',
        },
        amount: {
          type: 'number',
          title: 'Transaction amount in euros',
          description:
            "Original amount converted into euros - if this isn't provided by the bank, then it is automatically converted by Systemglue using the currency conversion rate queried from ERP",
        },
        transactionType: {
          type: 'string',
          enum: ['debit', 'credit'],
          title: 'Transaction type',
          description:
            'In debit transactions the Systemglue customer has made a payment to the resolved entity, and in credit transactions Systemglue customer receives a payment',
        },
      },
      required: [
        'bankPaymentId',
        'account',
        'entityName',
        'debtorName',
        'creditorName',
        'paymentISODate',
        'currencyCode',
        'originalAmount',
        'amount',
        'transactionType',
      ],
      title: 'Bank transaction',
    },
    bankConfiguration: {
      type: 'object',
      properties: {
        id: { type: 'string', title: 'Bank configuration id' },
        name: { type: 'string', title: 'Bank configuration name' },
        iban: {
          type: 'string',
          title: 'Bank account number',
          description: 'Bank account number in IBAN format',
        },
        bic: {
          type: 'string',
          title: 'Bank identifier',
          description: 'Bank identifier in BIC format',
        },
        glAccountInternalId: {
          type: 'string',
          title: 'COA account for the bank account',
        },
      },
      required: ['id', 'name', 'iban', 'bic', 'glAccountInternalId'],
      title: 'The bank configuration',
      description:
        'The bank configuration matching the account on the bank transaction',
    },
    erpEntities: {
      type: 'array',
      items: {
        type: 'object',
        properties: {
          entityName: {
            type: 'string',
            title: 'ERP entity name',
            description: 'Name of the entity as defined in ERP',
          },
          entityId: {
            type: 'string',
            title: 'ERP entity internal id',
            description: 'The internal id for the entity in ERP',
          },
          entityType: {
            type: 'string',
            enum: ['vendor', 'customer'],
            title: 'ERP entity type',
            description: 'The type of the entity',
          },
          invoiceGroupingEnabled: {
            type: 'boolean',
            title: 'ERP entity uses invoice grouping',
            description: 'Does entity have invoice grouping enabled?',
          },
        },
        required: ['entityName', 'entityId', 'entityType'],
      },
      title: 'List of suitable entities found in ERP',
    },
    erpTransactions: {
      type: 'array',
      items: {
        type: 'object',
        properties: {
          transactionType: {
            type: 'string',
            enum: ['vendor-bill', 'customer-invoice'],
            title: 'ERP transaction type',
            description: 'The type of the transaction in ERP',
          },
          entityId: {
            type: 'string',
            title: 'ERP transaction entity internal id',
            description: 'The internal id for the transaction owner in ERP',
          },
          reference: {
            type: 'string',
            title: 'ERP transaction reference',
            description:
              'The attribute in which references are stored in ERP can vary depending on the transaction type. Supported attribute names are defined in priority order in the application configuration and resolved reference is the one found first when querying transaction data.',
          },
          number: {
            type: 'string',
            title: 'ERP transaction number',
            description:
              'The attribute in which transaction numbers are stored in ERP can vary depending on the transaction type. Supported attribute names are defined in priority order in the application configuration and resolved number is the one found first when querying transaction data.',
          },
          createdISODate: {
            type: 'string',
            title: 'ERP transaction creation date',
            description: 'Transaction creation date in ISO format',
          },
          dueISODate: {
            type: 'string',
            title: 'ERP transaction due date',
            description: 'Transaction due date in ISO format',
          },
          internalId: {
            type: 'string',
            title: 'ERP transaction internal id',
            description: 'The internal id for the transaction in ERP',
          },
          amountTotal: {
            type: 'number',
            title: 'ERP transaction total original amount',
            description: 'The total amount for the transaction',
          },
          amountRemaining: {
            type: 'number',
            title: 'ERP transaction amount remaining',
            description: 'The amount currently open for the transaction',
          },
          currencyCode: {
            type: 'string',
            title: 'ERP transaction currency',
            description: 'The currency used in transaction in ISO format',
          },
        },
        required: [
          'transactionType',
          'entityId',
          'createdISODate',
          'dueISODate',
          'internalId',
          'amountTotal',
          'amountRemaining',
          'currencyCode',
        ],
      },
      title: 'List of suitable transactions found in ERP',
    },
  },
  required: [
    'bankTransaction',
    'bankConfiguration',
    'erpEntities',
    'erpTransactions',
  ],
  title: 'Resolve transaction data -input data model',
  description:
    'This model is processed with identification rules resulting in resolved entity and transactions',
  $schema: 'http://json-schema.org/draft-07/schema#',
};

export const resolveTransactionDataOutput = {
  type: 'object',
  properties: {
    bankTransaction: {
      type: 'object',
      properties: {
        bankPaymentId: {
          type: 'string',
          title: 'Unique identifier for the payment - provided by the bank',
        },
        account: {
          type: 'object',
          properties: {
            iban: {
              type: 'string',
              title: 'Transaction bank account number',
              description: 'Bank account number in IBAN format',
            },
            bic: {
              type: 'string',
              title: 'Transaction bank identifier',
              description: 'Bank identifier in BIC format',
            },
          },
          required: ['iban', 'bic'],
          title: 'The bank account',
          description: 'The bank account where this transaction happened',
        },
        entityName: {
          type: 'string',
          title: 'Transaction customer',
          description:
            'The name of the external customer decided by Systemglue - this is either the debtor or the creditor depending on transaction type',
        },
        debtorName: {
          type: 'string',
          title: 'The debtor name defined by the bank',
          description:
            'This is the original debtor name from the transaction data',
        },
        creditorName: {
          type: 'string',
          title: 'The creditor name defined by the bank',
          description:
            'This is the original creditor name from the transaction data',
        },
        originalMessage: {
          type: 'string',
          title: 'Transaction message',
          description:
            'This is the original message provided by the bank for this transaction',
        },
        message: {
          type: 'string',
          title: 'Pre-processed transaction message',
          description:
            'This is the pre-processed message from the transaction data. Sometimes banks provide the data in processed format, so we need to make it comparable',
        },
        originalReference: {
          type: 'string',
          title: 'Transaction national reference',
          description:
            'This is the original reference from the transaction data',
        },
        reference: {
          type: 'string',
          title: 'Pre-processed transaction national reference',
          description:
            'This is the pre-processed reference from the transaction data. Sometimes banks provide the data in processed format, so we need to make it comparable',
        },
        originalRfReference: {
          type: 'string',
          title: 'Transaction RF reference',
          description:
            'This is the original rfReference from the transaction data',
        },
        rfReference: {
          type: ['string', 'null'],
          title: 'Pre-processed transaction RF reference',
          description:
            'This is the pre-processed rfReference from the transaction data. Sometimes banks provide the data in processed format, so we need to make it comparable',
        },
        paymentISODate: {
          type: 'string',
          title: 'Transaction date',
          description:
            'This is the original payment date from the transaction data converted into ISO format',
        },
        currencyCode: {
          type: 'string',
          title: 'Transaction currency',
          description:
            'This is the original currency code as an ISO currency code from the transaction data',
        },
        originalAmount: {
          type: 'number',
          title: 'Transaction amount in originating currency',
          description:
            'Amount transferred in the transaction. Debit transactions are marked with a minus sign and credit transactions are positive',
        },
        amount: {
          type: 'number',
          title: 'Transaction amount in euros',
          description:
            "Original amount converted into euros - if this isn't provided by the bank, then it is automatically converted by Systemglue using the currency conversion rate queried from ERP",
        },
        transactionType: {
          type: 'string',
          enum: ['debit', 'credit'],
          title: 'Transaction type',
          description:
            'In debit transactions the Systemglue customer has made a payment to the resolved entity, and in credit transactions Systemglue customer receives a payment',
        },
      },
      required: [
        'bankPaymentId',
        'account',
        'entityName',
        'debtorName',
        'creditorName',
        'paymentISODate',
        'currencyCode',
        'originalAmount',
        'amount',
        'transactionType',
      ],
      title: 'The bank transaction',
      description: 'The bank transaction',
    },
    bankConfiguration: {
      type: 'object',
      properties: {
        id: { type: 'string', title: 'Bank configuration id' },
        name: { type: 'string', title: 'Bank configuration name' },
        iban: {
          type: 'string',
          title: 'Bank account number',
          description: 'Bank account number in IBAN format',
        },
        bic: {
          type: 'string',
          title: 'Bank identifier',
          description: 'Bank identifier in BIC format',
        },
        glAccountInternalId: {
          type: 'string',
          title: 'COA account for the bank account',
        },
      },
      required: ['id', 'name', 'iban', 'bic', 'glAccountInternalId'],
      title: 'The bank configuration',
      description:
        'The bank configuration matching the account on the bank transaction',
    },
    resolvedErpEntities: {
      type: 'array',
      items: {
        type: 'object',
        properties: {
          erpEntity: {
            type: 'object',
            properties: {
              entityName: {
                type: 'string',
                title: 'ERP entity name',
                description: 'Name of the entity as defined in ERP',
              },
              entityId: {
                type: 'string',
                title: 'ERP entity internal id',
                description: 'The internal id for the entity in ERP',
              },
              entityType: {
                type: 'string',
                enum: ['vendor', 'customer'],
                title: 'ERP entity type',
                description: 'The type of the entity',
              },
              invoiceGroupingEnabled: {
                type: 'boolean',
                title: 'ERP entity uses invoice grouping',
                description: 'Does entity have invoice grouping enabled?',
              },
            },
            required: ['entityName', 'entityId', 'entityType'],
          },
          matchedRule: {
            type: 'object',
            properties: {
              name: {
                type: 'string',
                description: 'Human-readable name for the rule',
              },
              description: {
                type: 'string',
                description: 'Human-readable description for the rule',
              },
              ruleId: {
                type: 'string',
                description: 'Internal id (UUID) for the rule',
              },
              condition: {
                type: 'string',
                description: 'Configure Json Logic rule as the condition',
              },
              isDefault: {
                type: 'boolean',
                description:
                  'Is this the default rule\n\nDefault rules can be overridden by making a customised version of the use case',
              },
              isLocked: {
                type: 'boolean',
                description:
                  'Is this rule locked from updates\n\nDefault rules will by default be updated - locking a rule will prevent automatic changes to it',
              },
              ruleGroup: {
                type: 'string',
                const: 'erp-entity-identification-rule',
                description: 'Rule type',
              },
              configuration: { description: 'Rule configuration' },
              handler: {
                type: 'string',
                enum: [
                  'identify-entity-automatically',
                  'identify-entity-by-exception',
                ],
              },
              disableAutomatedHandling: { type: 'boolean' },
            },
            required: ['condition', 'handler', 'name', 'ruleGroup', 'ruleId'],
          },
        },
        required: ['erpEntity'],
      },
      title: 'ERP entities identified',
      description: 'ERP entities identified',
    },
    resolvedErpTransactions: {
      type: 'array',
      items: {
        type: 'object',
        properties: {
          erpTransaction: {
            type: 'object',
            properties: {
              transactionType: {
                type: 'string',
                enum: ['vendor-bill', 'customer-invoice'],
                title: 'ERP transaction type',
                description: 'The type of the transaction in ERP',
              },
              entityId: {
                type: 'string',
                title: 'ERP transaction entity internal id',
                description: 'The internal id for the transaction owner in ERP',
              },
              reference: {
                type: 'string',
                title: 'ERP transaction reference',
                description:
                  'The attribute in which references are stored in ERP can vary depending on the transaction type. Supported attribute names are defined in priority order in the application configuration and resolved reference is the one found first when querying transaction data.',
              },
              number: {
                type: 'string',
                title: 'ERP transaction number',
                description:
                  'The attribute in which transaction numbers are stored in ERP can vary depending on the transaction type. Supported attribute names are defined in priority order in the application configuration and resolved number is the one found first when querying transaction data.',
              },
              createdISODate: {
                type: 'string',
                title: 'ERP transaction creation date',
                description: 'Transaction creation date in ISO format',
              },
              dueISODate: {
                type: 'string',
                title: 'ERP transaction due date',
                description: 'Transaction due date in ISO format',
              },
              internalId: {
                type: 'string',
                title: 'ERP transaction internal id',
                description: 'The internal id for the transaction in ERP',
              },
              amountTotal: {
                type: 'number',
                title: 'ERP transaction total original amount',
                description: 'The total amount for the transaction',
              },
              amountRemaining: {
                type: 'number',
                title: 'ERP transaction amount remaining',
                description: 'The amount currently open for the transaction',
              },
              currencyCode: {
                type: 'string',
                title: 'ERP transaction currency',
                description: 'The currency used in transaction in ISO format',
              },
            },
            required: [
              'transactionType',
              'entityId',
              'createdISODate',
              'dueISODate',
              'internalId',
              'amountTotal',
              'amountRemaining',
              'currencyCode',
            ],
          },
          matchedRule: {
            type: 'object',
            properties: {
              name: {
                type: 'string',
                description: 'Human-readable name for the rule',
              },
              description: {
                type: 'string',
                description: 'Human-readable description for the rule',
              },
              ruleId: {
                type: 'string',
                description: 'Internal id (UUID) for the rule',
              },
              condition: {
                type: 'string',
                description: 'Configure Json Logic rule as the condition',
              },
              isDefault: {
                type: 'boolean',
                description:
                  'Is this the default rule\n\nDefault rules can be overridden by making a customised version of the use case',
              },
              isLocked: {
                type: 'boolean',
                description:
                  'Is this rule locked from updates\n\nDefault rules will by default be updated - locking a rule will prevent automatic changes to it',
              },
              ruleGroup: {
                type: 'string',
                const: 'erp-transaction-identification-rule',
                description: 'Rule type',
              },
              configuration: { description: 'Rule configuration' },
              handler: {
                type: 'string',
                const: 'identify-transaction-automatically',
              },
              disableAutomatedHandling: { type: 'boolean' },
            },
            required: ['condition', 'handler', 'name', 'ruleGroup', 'ruleId'],
          },
        },
        required: ['erpTransaction'],
      },
      title: 'ERP transactions identified',
      description: 'ERP transactions identified',
    },
    resolvedNumberOfEntities: {
      type: 'number',
      title: 'Number of ERP entities identified',
      description: 'Number of ERP entities identified',
    },
    resolvedNumberOfTransactions: {
      type: 'number',
      title: 'Number of ERP transactions identified',
      description: 'Number of ERP transactions identified',
    },
    resolvedTotalTransactionSum: {
      type: 'number',
      title: 'Remaining amount in ERP transaction(s)',
      description: 'Remaining amount in ERP transaction(s)',
    },
    resolvedTotalDifference: {
      type: 'number',
      title:
        'Absolute difference between ERP remaining amount and transaction sum',
      description:
        'Absolute difference between remaining amount and transaction sum',
    },
    isNegativeDifference: {
      type: 'boolean',
      title: 'Is the difference a negative value or not?',
    },
    roundingTolerance: {
      type: 'number',
      title: 'Configured rounding tolerance',
      description: 'Configured rounding tolerance',
    },
  },
  required: [
    'bankTransaction',
    'bankConfiguration',
    'resolvedErpTransactions',
    'resolvedNumberOfEntities',
    'resolvedNumberOfTransactions',
    'resolvedTotalTransactionSum',
    'resolvedTotalDifference',
    'isNegativeDifference',
    'roundingTolerance',
  ],
  title: 'Resolve transaction data -output data model',
  description: 'This model is the result of processed identification rules',
  $schema: 'http://json-schema.org/draft-07/schema#',
};

export const resolveTransactionHandlingOutput = {
  type: 'object',
  properties: {
    transactionData: {
      type: 'object',
      properties: {
        bankTransaction: {
          type: 'object',
          properties: {
            bankPaymentId: {
              type: 'string',
              title: 'Unique identifier for the payment - provided by the bank',
            },
            account: {
              type: 'object',
              properties: {
                iban: {
                  type: 'string',
                  title: 'Transaction bank account number',
                  description: 'Bank account number in IBAN format',
                },
                bic: {
                  type: 'string',
                  title: 'Transaction bank identifier',
                  description: 'Bank identifier in BIC format',
                },
              },
              required: ['iban', 'bic'],
              title: 'The bank account',
              description: 'The bank account where this transaction happened',
            },
            entityName: {
              type: 'string',
              title: 'Transaction customer',
              description:
                'The name of the external customer decided by Systemglue - this is either the debtor or the creditor depending on transaction type',
            },
            debtorName: {
              type: 'string',
              title: 'The debtor name defined by the bank',
              description:
                'This is the original debtor name from the transaction data',
            },
            creditorName: {
              type: 'string',
              title: 'The creditor name defined by the bank',
              description:
                'This is the original creditor name from the transaction data',
            },
            originalMessage: {
              type: 'string',
              title: 'Transaction message',
              description:
                'This is the original message provided by the bank for this transaction',
            },
            message: {
              type: 'string',
              title: 'Pre-processed transaction message',
              description:
                'This is the pre-processed message from the transaction data. Sometimes banks provide the data in processed format, so we need to make it comparable',
            },
            originalReference: {
              type: 'string',
              title: 'Transaction national reference',
              description:
                'This is the original reference from the transaction data',
            },
            reference: {
              type: 'string',
              title: 'Pre-processed transaction national reference',
              description:
                'This is the pre-processed reference from the transaction data. Sometimes banks provide the data in processed format, so we need to make it comparable',
            },
            originalRfReference: {
              type: 'string',
              title: 'Transaction RF reference',
              description:
                'This is the original rfReference from the transaction data',
            },
            rfReference: {
              type: ['string', 'null'],
              title: 'Pre-processed transaction RF reference',
              description:
                'This is the pre-processed rfReference from the transaction data. Sometimes banks provide the data in processed format, so we need to make it comparable',
            },
            paymentISODate: {
              type: 'string',
              title: 'Transaction date',
              description:
                'This is the original payment date from the transaction data converted into ISO format',
            },
            currencyCode: {
              type: 'string',
              title: 'Transaction currency',
              description:
                'This is the original currency code as an ISO currency code from the transaction data',
            },
            originalAmount: {
              type: 'number',
              title: 'Transaction amount in originating currency',
              description:
                'Amount transferred in the transaction. Debit transactions are marked with a minus sign and credit transactions are positive',
            },
            amount: {
              type: 'number',
              title: 'Transaction amount in euros',
              description:
                "Original amount converted into euros - if this isn't provided by the bank, then it is automatically converted by Systemglue using the currency conversion rate queried from ERP",
            },
            transactionType: {
              type: 'string',
              enum: ['debit', 'credit'],
              title: 'Transaction type',
              description:
                'In debit transactions the Systemglue customer has made a payment to the resolved entity, and in credit transactions Systemglue customer receives a payment',
            },
          },
          required: [
            'bankPaymentId',
            'account',
            'entityName',
            'debtorName',
            'creditorName',
            'paymentISODate',
            'currencyCode',
            'originalAmount',
            'amount',
            'transactionType',
          ],
          title: 'The bank transaction',
          description: 'The bank transaction',
        },
        bankConfiguration: {
          type: 'object',
          properties: {
            id: { type: 'string', title: 'Bank configuration id' },
            name: { type: 'string', title: 'Bank configuration name' },
            iban: {
              type: 'string',
              title: 'Bank account number',
              description: 'Bank account number in IBAN format',
            },
            bic: {
              type: 'string',
              title: 'Bank identifier',
              description: 'Bank identifier in BIC format',
            },
            glAccountInternalId: {
              type: 'string',
              title: 'COA account for the bank account',
            },
          },
          required: ['id', 'name', 'iban', 'bic', 'glAccountInternalId'],
          title: 'The bank configuration',
          description:
            'The bank configuration matching the account on the bank transaction',
        },
        resolvedErpEntities: {
          type: 'array',
          items: {
            type: 'object',
            properties: {
              erpEntity: {
                type: 'object',
                properties: {
                  entityName: {
                    type: 'string',
                    title: 'ERP entity name',
                    description: 'Name of the entity as defined in ERP',
                  },
                  entityId: {
                    type: 'string',
                    title: 'ERP entity internal id',
                    description: 'The internal id for the entity in ERP',
                  },
                  entityType: {
                    type: 'string',
                    enum: ['vendor', 'customer'],
                    title: 'ERP entity type',
                    description: 'The type of the entity',
                  },
                  invoiceGroupingEnabled: {
                    type: 'boolean',
                    title: 'ERP entity uses invoice grouping',
                    description: 'Does entity have invoice grouping enabled?',
                  },
                },
                required: ['entityName', 'entityId', 'entityType'],
              },
              matchedRule: {
                type: 'object',
                properties: {
                  name: {
                    type: 'string',
                    description: 'Human-readable name for the rule',
                  },
                  description: {
                    type: 'string',
                    description: 'Human-readable description for the rule',
                  },
                  ruleId: {
                    type: 'string',
                    description: 'Internal id (UUID) for the rule',
                  },
                  condition: {
                    type: 'string',
                    description: 'Configure Json Logic rule as the condition',
                  },
                  isDefault: {
                    type: 'boolean',
                    description:
                      'Is this the default rule\n\nDefault rules can be overridden by making a customised version of the use case',
                  },
                  isLocked: {
                    type: 'boolean',
                    description:
                      'Is this rule locked from updates\n\nDefault rules will by default be updated - locking a rule will prevent automatic changes to it',
                  },
                  ruleGroup: {
                    type: 'string',
                    const: 'erp-entity-identification-rule',
                    description: 'Rule type',
                  },
                  configuration: { description: 'Rule configuration' },
                  handler: {
                    type: 'string',
                    enum: [
                      'identify-entity-automatically',
                      'identify-entity-by-exception',
                    ],
                  },
                  disableAutomatedHandling: { type: 'boolean' },
                },
                required: [
                  'condition',
                  'handler',
                  'name',
                  'ruleGroup',
                  'ruleId',
                ],
              },
            },
            required: ['erpEntity'],
          },
          title: 'ERP entities identified',
          description: 'ERP entities identified',
        },
        resolvedErpTransactions: {
          type: 'array',
          items: {
            type: 'object',
            properties: {
              erpTransaction: {
                type: 'object',
                properties: {
                  transactionType: {
                    type: 'string',
                    enum: ['vendor-bill', 'customer-invoice'],
                    title: 'ERP transaction type',
                    description: 'The type of the transaction in ERP',
                  },
                  entityId: {
                    type: 'string',
                    title: 'ERP transaction entity internal id',
                    description:
                      'The internal id for the transaction owner in ERP',
                  },
                  reference: {
                    type: 'string',
                    title: 'ERP transaction reference',
                    description:
                      'The attribute in which references are stored in ERP can vary depending on the transaction type. Supported attribute names are defined in priority order in the application configuration and resolved reference is the one found first when querying transaction data.',
                  },
                  number: {
                    type: 'string',
                    title: 'ERP transaction number',
                    description:
                      'The attribute in which transaction numbers are stored in ERP can vary depending on the transaction type. Supported attribute names are defined in priority order in the application configuration and resolved number is the one found first when querying transaction data.',
                  },
                  createdISODate: {
                    type: 'string',
                    title: 'ERP transaction creation date',
                    description: 'Transaction creation date in ISO format',
                  },
                  dueISODate: {
                    type: 'string',
                    title: 'ERP transaction due date',
                    description: 'Transaction due date in ISO format',
                  },
                  internalId: {
                    type: 'string',
                    title: 'ERP transaction internal id',
                    description: 'The internal id for the transaction in ERP',
                  },
                  amountTotal: {
                    type: 'number',
                    title: 'ERP transaction total original amount',
                    description: 'The total amount for the transaction',
                  },
                  amountRemaining: {
                    type: 'number',
                    title: 'ERP transaction amount remaining',
                    description:
                      'The amount currently open for the transaction',
                  },
                  currencyCode: {
                    type: 'string',
                    title: 'ERP transaction currency',
                    description:
                      'The currency used in transaction in ISO format',
                  },
                },
                required: [
                  'transactionType',
                  'entityId',
                  'createdISODate',
                  'dueISODate',
                  'internalId',
                  'amountTotal',
                  'amountRemaining',
                  'currencyCode',
                ],
              },
              matchedRule: {
                type: 'object',
                properties: {
                  name: {
                    type: 'string',
                    description: 'Human-readable name for the rule',
                  },
                  description: {
                    type: 'string',
                    description: 'Human-readable description for the rule',
                  },
                  ruleId: {
                    type: 'string',
                    description: 'Internal id (UUID) for the rule',
                  },
                  condition: {
                    type: 'string',
                    description: 'Configure Json Logic rule as the condition',
                  },
                  isDefault: {
                    type: 'boolean',
                    description:
                      'Is this the default rule\n\nDefault rules can be overridden by making a customised version of the use case',
                  },
                  isLocked: {
                    type: 'boolean',
                    description:
                      'Is this rule locked from updates\n\nDefault rules will by default be updated - locking a rule will prevent automatic changes to it',
                  },
                  ruleGroup: {
                    type: 'string',
                    const: 'erp-transaction-identification-rule',
                    description: 'Rule type',
                  },
                  configuration: { description: 'Rule configuration' },
                  handler: {
                    type: 'string',
                    const: 'identify-transaction-automatically',
                  },
                  disableAutomatedHandling: { type: 'boolean' },
                },
                required: [
                  'condition',
                  'handler',
                  'name',
                  'ruleGroup',
                  'ruleId',
                ],
              },
            },
            required: ['erpTransaction'],
          },
          title: 'ERP transactions identified',
          description: 'ERP transactions identified',
        },
        resolvedNumberOfEntities: {
          type: 'number',
          title: 'Number of ERP entities identified',
          description: 'Number of ERP entities identified',
        },
        resolvedNumberOfTransactions: {
          type: 'number',
          title: 'Number of ERP transactions identified',
          description: 'Number of ERP transactions identified',
        },
        resolvedTotalTransactionSum: {
          type: 'number',
          title: 'Remaining amount in ERP transaction(s)',
          description: 'Remaining amount in ERP transaction(s)',
        },
        resolvedTotalDifference: {
          type: 'number',
          title:
            'Absolute difference between ERP remaining amount and transaction sum',
          description:
            'Absolute difference between remaining amount and transaction sum',
        },
        isNegativeDifference: {
          type: 'boolean',
          title: 'Is the difference a negative value or not?',
        },
        roundingTolerance: {
          type: 'number',
          title: 'Configured rounding tolerance',
          description: 'Configured rounding tolerance',
        },
      },
      required: [
        'bankTransaction',
        'bankConfiguration',
        'resolvedErpTransactions',
        'resolvedNumberOfEntities',
        'resolvedNumberOfTransactions',
        'resolvedTotalTransactionSum',
        'resolvedTotalDifference',
        'isNegativeDifference',
        'roundingTolerance',
      ],
      title: 'Resolve transaction data -output data model',
      description: 'This model is the result of processed identification rules',
    },
    resolvedHandlers: {
      type: 'array',
      items: {
        type: 'object',
        properties: {
          matchedRule: {
            type: 'object',
            properties: {
              name: {
                type: 'string',
                description: 'Human-readable name for the rule',
              },
              description: {
                type: 'string',
                description: 'Human-readable description for the rule',
              },
              ruleId: {
                type: 'string',
                description: 'Internal id (UUID) for the rule',
              },
              condition: {
                type: 'string',
                description: 'Configure Json Logic rule as the condition',
              },
              isDefault: {
                type: 'boolean',
                description:
                  'Is this the default rule\n\nDefault rules can be overridden by making a customised version of the use case',
              },
              isLocked: {
                type: 'boolean',
                description:
                  'Is this rule locked from updates\n\nDefault rules will by default be updated - locking a rule will prevent automatic changes to it',
              },
              ruleGroup: {
                type: 'string',
                const: 'bank-transaction-handling-rule',
                description: 'Rule type',
              },
              configuration: { description: 'Rule configuration' },
              handler: {
                type: 'string',
                enum: [
                  'generate-customer-payments-per-identified-transaction',
                  'generate-customer-payments-per-identified-transaction-and-a-journal-entry-of-difference',
                  'generate-vendor-payments-per-identified-transaction',
                  'generate-vendor-payments-per-identified-transaction-and-a-journal-entry-of-difference',
                  'generate-consolidated-customer-payments-per-identified-transaction',
                ],
              },
              disableAutomatedHandling: { type: 'boolean' },
            },
            required: ['condition', 'handler', 'name', 'ruleGroup', 'ruleId'],
          },
          actions: {
            type: 'array',
            items: {
              type: 'object',
              properties: {
                actionType: {
                  type: 'string',
                  enum: [
                    'create-vendor-payment',
                    'create-customer-payment',
                    'create-consolidated-customer-payment',
                    'create-journal-entry',
                  ],
                },
                transactionInternalId: {
                  type: 'string',
                  description:
                    'Reference to the matched transaction\n\nThis can be a VendorBill, Invoice, etc. depending on the action',
                },
                accountInternalId: {
                  type: 'string',
                  description:
                    'The account to which a transaction will be made\n\nUsed only for journal entries',
                },
                amount: {
                  type: 'number',
                  description: 'Amount of the new transaction',
                },
              },
              required: ['actionType', 'amount'],
            },
          },
        },
        required: ['actions'],
      },
    },
    resolvedNumberOfHandlers: { type: 'number' },
  },
  required: ['transactionData', 'resolvedHandlers', 'resolvedNumberOfHandlers'],
  title: 'Resolve transaction data -output data model',
  description: 'This model is the result of processed identification rules',
  $schema: 'http://json-schema.org/draft-07/schema#',
};

export const resolveBankTransactionSubscriptionInput = {
  type: 'object',
  properties: {
    account: { type: 'string', title: 'Account number in IBAN -format' },
  },
  required: ['account'],
  title: 'Resolve bank transaction subscription input data model',
  $schema: 'http://json-schema.org/draft-07/schema#',
};

export const upsertBankStatementInErpInput = {
  type: 'object',
  properties: {
    transactionData: {
      type: 'object',
      properties: {
        bankTransaction: {
          type: 'object',
          properties: {
            bankPaymentId: {
              type: 'string',
              title: 'Unique identifier for the payment - provided by the bank',
            },
            account: {
              type: 'object',
              properties: {
                iban: {
                  type: 'string',
                  title: 'Transaction bank account number',
                  description: 'Bank account number in IBAN format',
                },
                bic: {
                  type: 'string',
                  title: 'Transaction bank identifier',
                  description: 'Bank identifier in BIC format',
                },
              },
              required: ['iban', 'bic'],
              title: 'The bank account',
              description: 'The bank account where this transaction happened',
            },
            entityName: {
              type: 'string',
              title: 'Transaction customer',
              description:
                'The name of the external customer decided by Systemglue - this is either the debtor or the creditor depending on transaction type',
            },
            debtorName: {
              type: 'string',
              title: 'The debtor name defined by the bank',
              description:
                'This is the original debtor name from the transaction data',
            },
            creditorName: {
              type: 'string',
              title: 'The creditor name defined by the bank',
              description:
                'This is the original creditor name from the transaction data',
            },
            originalMessage: {
              type: 'string',
              title: 'Transaction message',
              description:
                'This is the original message provided by the bank for this transaction',
            },
            message: {
              type: 'string',
              title: 'Pre-processed transaction message',
              description:
                'This is the pre-processed message from the transaction data. Sometimes banks provide the data in processed format, so we need to make it comparable',
            },
            originalReference: {
              type: 'string',
              title: 'Transaction national reference',
              description:
                'This is the original reference from the transaction data',
            },
            reference: {
              type: 'string',
              title: 'Pre-processed transaction national reference',
              description:
                'This is the pre-processed reference from the transaction data. Sometimes banks provide the data in processed format, so we need to make it comparable',
            },
            originalRfReference: {
              type: 'string',
              title: 'Transaction RF reference',
              description:
                'This is the original rfReference from the transaction data',
            },
            rfReference: {
              type: ['string', 'null'],
              title: 'Pre-processed transaction RF reference',
              description:
                'This is the pre-processed rfReference from the transaction data. Sometimes banks provide the data in processed format, so we need to make it comparable',
            },
            paymentISODate: {
              type: 'string',
              title: 'Transaction date',
              description:
                'This is the original payment date from the transaction data converted into ISO format',
            },
            currencyCode: {
              type: 'string',
              title: 'Transaction currency',
              description:
                'This is the original currency code as an ISO currency code from the transaction data',
            },
            originalAmount: {
              type: 'number',
              title: 'Transaction amount in originating currency',
              description:
                'Amount transferred in the transaction. Debit transactions are marked with a minus sign and credit transactions are positive',
            },
            amount: {
              type: 'number',
              title: 'Transaction amount in euros',
              description:
                "Original amount converted into euros - if this isn't provided by the bank, then it is automatically converted by Systemglue using the currency conversion rate queried from ERP",
            },
            transactionType: {
              type: 'string',
              enum: ['debit', 'credit'],
              title: 'Transaction type',
              description:
                'In debit transactions the Systemglue customer has made a payment to the resolved entity, and in credit transactions Systemglue customer receives a payment',
            },
          },
          required: [
            'bankPaymentId',
            'account',
            'entityName',
            'debtorName',
            'creditorName',
            'paymentISODate',
            'currencyCode',
            'originalAmount',
            'amount',
            'transactionType',
          ],
          title: 'The bank transaction',
          description: 'The bank transaction',
        },
        bankConfiguration: {
          type: 'object',
          properties: {
            id: { type: 'string', title: 'Bank configuration id' },
            name: { type: 'string', title: 'Bank configuration name' },
            iban: {
              type: 'string',
              title: 'Bank account number',
              description: 'Bank account number in IBAN format',
            },
            bic: {
              type: 'string',
              title: 'Bank identifier',
              description: 'Bank identifier in BIC format',
            },
            glAccountInternalId: {
              type: 'string',
              title: 'COA account for the bank account',
            },
          },
          required: ['id', 'name', 'iban', 'bic', 'glAccountInternalId'],
          title: 'The bank configuration',
          description:
            'The bank configuration matching the account on the bank transaction',
        },
        resolvedErpEntities: {
          type: 'array',
          items: {
            type: 'object',
            properties: {
              erpEntity: {
                type: 'object',
                properties: {
                  entityName: {
                    type: 'string',
                    title: 'ERP entity name',
                    description: 'Name of the entity as defined in ERP',
                  },
                  entityId: {
                    type: 'string',
                    title: 'ERP entity internal id',
                    description: 'The internal id for the entity in ERP',
                  },
                  entityType: {
                    type: 'string',
                    enum: ['vendor', 'customer'],
                    title: 'ERP entity type',
                    description: 'The type of the entity',
                  },
                  invoiceGroupingEnabled: {
                    type: 'boolean',
                    title: 'ERP entity uses invoice grouping',
                    description: 'Does entity have invoice grouping enabled?',
                  },
                },
                required: ['entityName', 'entityId', 'entityType'],
              },
              matchedRule: {
                type: 'object',
                properties: {
                  name: {
                    type: 'string',
                    description: 'Human-readable name for the rule',
                  },
                  description: {
                    type: 'string',
                    description: 'Human-readable description for the rule',
                  },
                  ruleId: {
                    type: 'string',
                    description: 'Internal id (UUID) for the rule',
                  },
                  condition: {
                    type: 'string',
                    description: 'Configure Json Logic rule as the condition',
                  },
                  isDefault: {
                    type: 'boolean',
                    description:
                      'Is this the default rule\n\nDefault rules can be overridden by making a customised version of the use case',
                  },
                  isLocked: {
                    type: 'boolean',
                    description:
                      'Is this rule locked from updates\n\nDefault rules will by default be updated - locking a rule will prevent automatic changes to it',
                  },
                  ruleGroup: {
                    type: 'string',
                    const: 'erp-entity-identification-rule',
                    description: 'Rule type',
                  },
                  configuration: { description: 'Rule configuration' },
                  handler: {
                    type: 'string',
                    enum: [
                      'identify-entity-automatically',
                      'identify-entity-by-exception',
                    ],
                  },
                  disableAutomatedHandling: { type: 'boolean' },
                },
                required: [
                  'condition',
                  'handler',
                  'name',
                  'ruleGroup',
                  'ruleId',
                ],
              },
            },
            required: ['erpEntity'],
          },
          title: 'ERP entities identified',
          description: 'ERP entities identified',
        },
        resolvedErpTransactions: {
          type: 'array',
          items: {
            type: 'object',
            properties: {
              erpTransaction: {
                type: 'object',
                properties: {
                  transactionType: {
                    type: 'string',
                    enum: ['vendor-bill', 'customer-invoice'],
                    title: 'ERP transaction type',
                    description: 'The type of the transaction in ERP',
                  },
                  entityId: {
                    type: 'string',
                    title: 'ERP transaction entity internal id',
                    description:
                      'The internal id for the transaction owner in ERP',
                  },
                  reference: {
                    type: 'string',
                    title: 'ERP transaction reference',
                    description:
                      'The attribute in which references are stored in ERP can vary depending on the transaction type. Supported attribute names are defined in priority order in the application configuration and resolved reference is the one found first when querying transaction data.',
                  },
                  number: {
                    type: 'string',
                    title: 'ERP transaction number',
                    description:
                      'The attribute in which transaction numbers are stored in ERP can vary depending on the transaction type. Supported attribute names are defined in priority order in the application configuration and resolved number is the one found first when querying transaction data.',
                  },
                  createdISODate: {
                    type: 'string',
                    title: 'ERP transaction creation date',
                    description: 'Transaction creation date in ISO format',
                  },
                  dueISODate: {
                    type: 'string',
                    title: 'ERP transaction due date',
                    description: 'Transaction due date in ISO format',
                  },
                  internalId: {
                    type: 'string',
                    title: 'ERP transaction internal id',
                    description: 'The internal id for the transaction in ERP',
                  },
                  amountTotal: {
                    type: 'number',
                    title: 'ERP transaction total original amount',
                    description: 'The total amount for the transaction',
                  },
                  amountRemaining: {
                    type: 'number',
                    title: 'ERP transaction amount remaining',
                    description:
                      'The amount currently open for the transaction',
                  },
                  currencyCode: {
                    type: 'string',
                    title: 'ERP transaction currency',
                    description:
                      'The currency used in transaction in ISO format',
                  },
                },
                required: [
                  'transactionType',
                  'entityId',
                  'createdISODate',
                  'dueISODate',
                  'internalId',
                  'amountTotal',
                  'amountRemaining',
                  'currencyCode',
                ],
              },
              matchedRule: {
                type: 'object',
                properties: {
                  name: {
                    type: 'string',
                    description: 'Human-readable name for the rule',
                  },
                  description: {
                    type: 'string',
                    description: 'Human-readable description for the rule',
                  },
                  ruleId: {
                    type: 'string',
                    description: 'Internal id (UUID) for the rule',
                  },
                  condition: {
                    type: 'string',
                    description: 'Configure Json Logic rule as the condition',
                  },
                  isDefault: {
                    type: 'boolean',
                    description:
                      'Is this the default rule\n\nDefault rules can be overridden by making a customised version of the use case',
                  },
                  isLocked: {
                    type: 'boolean',
                    description:
                      'Is this rule locked from updates\n\nDefault rules will by default be updated - locking a rule will prevent automatic changes to it',
                  },
                  ruleGroup: {
                    type: 'string',
                    const: 'erp-transaction-identification-rule',
                    description: 'Rule type',
                  },
                  configuration: { description: 'Rule configuration' },
                  handler: {
                    type: 'string',
                    const: 'identify-transaction-automatically',
                  },
                  disableAutomatedHandling: { type: 'boolean' },
                },
                required: [
                  'condition',
                  'handler',
                  'name',
                  'ruleGroup',
                  'ruleId',
                ],
              },
            },
            required: ['erpTransaction'],
          },
          title: 'ERP transactions identified',
          description: 'ERP transactions identified',
        },
        resolvedNumberOfEntities: {
          type: 'number',
          title: 'Number of ERP entities identified',
          description: 'Number of ERP entities identified',
        },
        resolvedNumberOfTransactions: {
          type: 'number',
          title: 'Number of ERP transactions identified',
          description: 'Number of ERP transactions identified',
        },
        resolvedTotalTransactionSum: {
          type: 'number',
          title: 'Remaining amount in ERP transaction(s)',
          description: 'Remaining amount in ERP transaction(s)',
        },
        resolvedTotalDifference: {
          type: 'number',
          title:
            'Absolute difference between ERP remaining amount and transaction sum',
          description:
            'Absolute difference between remaining amount and transaction sum',
        },
        isNegativeDifference: {
          type: 'boolean',
          title: 'Is the difference a negative value or not?',
        },
        roundingTolerance: {
          type: 'number',
          title: 'Configured rounding tolerance',
          description: 'Configured rounding tolerance',
        },
      },
      required: [
        'bankTransaction',
        'bankConfiguration',
        'resolvedErpTransactions',
        'resolvedNumberOfEntities',
        'resolvedNumberOfTransactions',
        'resolvedTotalTransactionSum',
        'resolvedTotalDifference',
        'isNegativeDifference',
        'roundingTolerance',
      ],
      title: 'Resolve transaction data -output data model',
      description: 'This model is the result of processed identification rules',
    },
    resolvedHandlers: {
      type: 'array',
      items: {
        type: 'object',
        properties: {
          matchedRule: {
            type: 'object',
            properties: {
              name: {
                type: 'string',
                description: 'Human-readable name for the rule',
              },
              description: {
                type: 'string',
                description: 'Human-readable description for the rule',
              },
              ruleId: {
                type: 'string',
                description: 'Internal id (UUID) for the rule',
              },
              condition: {
                type: 'string',
                description: 'Configure Json Logic rule as the condition',
              },
              isDefault: {
                type: 'boolean',
                description:
                  'Is this the default rule\n\nDefault rules can be overridden by making a customised version of the use case',
              },
              isLocked: {
                type: 'boolean',
                description:
                  'Is this rule locked from updates\n\nDefault rules will by default be updated - locking a rule will prevent automatic changes to it',
              },
              ruleGroup: {
                type: 'string',
                const: 'bank-transaction-handling-rule',
                description: 'Rule type',
              },
              configuration: { description: 'Rule configuration' },
              handler: {
                type: 'string',
                enum: [
                  'generate-customer-payments-per-identified-transaction',
                  'generate-customer-payments-per-identified-transaction-and-a-journal-entry-of-difference',
                  'generate-vendor-payments-per-identified-transaction',
                  'generate-vendor-payments-per-identified-transaction-and-a-journal-entry-of-difference',
                  'generate-consolidated-customer-payments-per-identified-transaction',
                ],
              },
              disableAutomatedHandling: { type: 'boolean' },
            },
            required: ['condition', 'handler', 'name', 'ruleGroup', 'ruleId'],
          },
          actions: {
            type: 'array',
            items: {
              type: 'object',
              properties: {
                actionType: {
                  type: 'string',
                  enum: [
                    'create-vendor-payment',
                    'create-customer-payment',
                    'create-consolidated-customer-payment',
                    'create-journal-entry',
                  ],
                },
                transactionInternalId: {
                  type: 'string',
                  description:
                    'Reference to the matched transaction\n\nThis can be a VendorBill, Invoice, etc. depending on the action',
                },
                accountInternalId: {
                  type: 'string',
                  description:
                    'The account to which a transaction will be made\n\nUsed only for journal entries',
                },
                amount: {
                  type: 'number',
                  description: 'Amount of the new transaction',
                },
              },
              required: ['actionType', 'amount'],
            },
          },
        },
        required: ['actions'],
      },
    },
    resolvedNumberOfHandlers: { type: 'number' },
  },
  required: ['transactionData', 'resolvedHandlers', 'resolvedNumberOfHandlers'],
  title: 'Upsert bank statement in ERP -input data model',
  description: 'This model is the result of processed identification rules',
  $schema: 'http://json-schema.org/draft-07/schema#',
};

export const listAvailableActionsFromErpOutput = {
  type: 'object',
  properties: {
    actions: {
      type: 'array',
      items: {
        type: 'object',
        properties: {
          id: { type: 'string', title: 'Internal id' },
          externalId: { type: 'string', title: 'External id' },
          coaBankAccountId: {
            type: 'string',
            title: 'Bank account (in chart of accounts) related to the action',
          },
          postingAccountId: {
            type: 'string',
            title: 'Posting account related to the action',
          },
          amount: { type: 'number', title: 'Amount in the bank transaction' },
          transactionToCreate: {
            type: 'string',
            enum: ['vendor-payment', 'customer-payment', 'journal-entry'],
            title: 'Transaction type to create',
            description:
              'Type of the transaction to create when processing the action',
          },
          relatedTransactionId: {
            type: 'string',
            title: 'Id of the related transaction',
          },
          processingAllowed: {
            type: 'boolean',
            title: 'Can this be processed?',
          },
          statementLogId: { type: 'string', title: 'Statement log' },
          subsidiaryId: { type: 'string', title: 'Subsidiary' },
        },
        required: [
          'id',
          'coaBankAccountId',
          'amount',
          'transactionToCreate',
          'relatedTransactionId',
          'processingAllowed',
          'statementLogId',
          'subsidiaryId',
        ],
      },
    },
  },
  required: ['actions'],
  title: 'List bank statement actions output data model',
  $schema: 'http://json-schema.org/draft-07/schema#',
};

export const processErpBankStatementActionInput = {
  type: 'object',
  properties: {
    id: { type: 'string', title: 'Internal id of the action in ERP' },
  },
  required: ['id'],
  title: 'Process bank statement action input data model',
  $schema: 'http://json-schema.org/draft-07/schema#',
};

export const resolveBankTransactionSubscriptionOutput = {
  type: 'object',
  properties: {
    subscription: {
      type: 'object',
      properties: {
        id: { type: 'string' },
        app: {
          type: 'object',
          properties: {
            name: {
              type: 'string',
              const: 'bank-transaction-integration-app',
              title: 'Application name',
            },
            configuration: {
              type: 'object',
              properties: {
                connections: {
                  type: 'object',
                  properties: {
                    erp: {
                      type: 'string',
                      const: 'netsuite',
                      title: 'Used ERP',
                    },
                    bank: {
                      type: 'string',
                      enum: ['osuuspankki', 'qonto'],
                      title: 'Used bank',
                    },
                  },
                  required: ['erp', 'bank'],
                  title: 'System connections',
                },
                account: {
                  type: 'object',
                  properties: {
                    iban: {
                      type: 'string',
                      title: "Subsidiary's bank account number in IBAN -format",
                      description:
                        'This is used to match incoming payments to configurations',
                    },
                    bic: {
                      type: 'string',
                      title:
                        "Subsidiary's bank identification code in BIC -format",
                    },
                  },
                  required: ['iban', 'bic'],
                  title: 'Bank account details',
                },
                transactions: {
                  type: 'object',
                  properties: {
                    referenceFieldName: { type: 'string' },
                    referenceFieldName2: { type: 'string' },
                    referenceFieldName3: { type: 'string' },
                    referenceFieldName4: { type: 'string' },
                    referenceFieldName5: { type: 'string' },
                    numberFieldName: { type: 'string' },
                    numberFieldName2: { type: 'string' },
                    numberFieldName3: { type: 'string' },
                    numberFieldName4: { type: 'string' },
                    numberFieldName5: { type: 'string' },
                  },
                  required: ['referenceFieldName', 'numberFieldName'],
                },
                general: {
                  type: 'object',
                  properties: {
                    dateFormat: { type: 'string' },
                    roundingTolerance: { type: 'number' },
                  },
                  required: ['dateFormat'],
                },
                rules: {
                  type: 'object',
                  properties: {
                    erpEntityIdentification: {
                      type: 'array',
                      items: {
                        type: 'object',
                        properties: {
                          name: {
                            type: 'string',
                            description: 'Human-readable name for the rule',
                          },
                          description: {
                            type: 'string',
                            description:
                              'Human-readable description for the rule',
                          },
                          ruleId: {
                            type: 'string',
                            description: 'Internal id (UUID) for the rule',
                          },
                          condition: {
                            type: 'string',
                            description:
                              'Configure Json Logic rule as the condition',
                          },
                          isDefault: {
                            type: 'boolean',
                            description:
                              'Is this the default rule\n\nDefault rules can be overridden by making a customised version of the use case',
                          },
                          isLocked: {
                            type: 'boolean',
                            description:
                              'Is this rule locked from updates\n\nDefault rules will by default be updated - locking a rule will prevent automatic changes to it',
                          },
                          ruleGroup: {
                            type: 'string',
                            const: 'erp-entity-identification-rule',
                            description: 'Rule type',
                          },
                          configuration: { description: 'Rule configuration' },
                          handler: {
                            type: 'string',
                            enum: [
                              'identify-entity-automatically',
                              'identify-entity-by-exception',
                            ],
                          },
                          disableAutomatedHandling: { type: 'boolean' },
                        },
                        required: [
                          'condition',
                          'handler',
                          'name',
                          'ruleGroup',
                          'ruleId',
                        ],
                      },
                    },
                    erpTransactionIdentification: {
                      type: 'array',
                      items: {
                        type: 'object',
                        properties: {
                          name: {
                            type: 'string',
                            description: 'Human-readable name for the rule',
                          },
                          description: {
                            type: 'string',
                            description:
                              'Human-readable description for the rule',
                          },
                          ruleId: {
                            type: 'string',
                            description: 'Internal id (UUID) for the rule',
                          },
                          condition: {
                            type: 'string',
                            description:
                              'Configure Json Logic rule as the condition',
                          },
                          isDefault: {
                            type: 'boolean',
                            description:
                              'Is this the default rule\n\nDefault rules can be overridden by making a customised version of the use case',
                          },
                          isLocked: {
                            type: 'boolean',
                            description:
                              'Is this rule locked from updates\n\nDefault rules will by default be updated - locking a rule will prevent automatic changes to it',
                          },
                          ruleGroup: {
                            type: 'string',
                            const: 'erp-transaction-identification-rule',
                            description: 'Rule type',
                          },
                          configuration: { description: 'Rule configuration' },
                          handler: {
                            type: 'string',
                            const: 'identify-transaction-automatically',
                          },
                          disableAutomatedHandling: { type: 'boolean' },
                        },
                        required: [
                          'condition',
                          'handler',
                          'name',
                          'ruleGroup',
                          'ruleId',
                        ],
                      },
                    },
                    bankTransactionHandling: {
                      type: 'array',
                      items: {
                        type: 'object',
                        properties: {
                          name: {
                            type: 'string',
                            description: 'Human-readable name for the rule',
                          },
                          description: {
                            type: 'string',
                            description:
                              'Human-readable description for the rule',
                          },
                          ruleId: {
                            type: 'string',
                            description: 'Internal id (UUID) for the rule',
                          },
                          condition: {
                            type: 'string',
                            description:
                              'Configure Json Logic rule as the condition',
                          },
                          isDefault: {
                            type: 'boolean',
                            description:
                              'Is this the default rule\n\nDefault rules can be overridden by making a customised version of the use case',
                          },
                          isLocked: {
                            type: 'boolean',
                            description:
                              'Is this rule locked from updates\n\nDefault rules will by default be updated - locking a rule will prevent automatic changes to it',
                          },
                          ruleGroup: {
                            type: 'string',
                            const: 'bank-transaction-handling-rule',
                            description: 'Rule type',
                          },
                          configuration: { description: 'Rule configuration' },
                          handler: {
                            type: 'string',
                            enum: [
                              'generate-customer-payments-per-identified-transaction',
                              'generate-customer-payments-per-identified-transaction-and-a-journal-entry-of-difference',
                              'generate-vendor-payments-per-identified-transaction',
                              'generate-vendor-payments-per-identified-transaction-and-a-journal-entry-of-difference',
                              'generate-consolidated-customer-payments-per-identified-transaction',
                            ],
                          },
                          disableAutomatedHandling: { type: 'boolean' },
                        },
                        required: [
                          'condition',
                          'handler',
                          'name',
                          'ruleGroup',
                          'ruleId',
                        ],
                      },
                    },
                  },
                  required: [
                    'erpEntityIdentification',
                    'erpTransactionIdentification',
                    'bankTransactionHandling',
                  ],
                },
              },
              required: ['connections', 'account', 'transactions', 'general'],
            },
          },
          required: ['configuration', 'name'],
        },
        status: {
          type: 'string',
          enum: [
            'Created',
            'Configuring',
            'Configured',
            'ConfigurationFailed',
            'Active',
            'Cancelling',
            'Cancelled',
            'Deleted',
          ],
          title: 'Internally used status enum for subscriptions',
        },
        subscriptionDetails: {
          type: 'object',
          properties: {
            name: {
              type: 'string',
              description: 'User-definable name for the integration',
            },
            disabled: { type: 'boolean' },
            startDate: { type: 'string' },
            endDate: { type: 'string' },
            createdDate: { type: 'string' },
            updatedDate: { type: 'string' },
          },
          required: ['startDate', 'createdDate', 'updatedDate'],
        },
      },
      required: ['id', 'app', 'status', 'subscriptionDetails'],
      title:
        'Enabled subscription matching the given account - undefined if not found',
    },
  },
  title: 'Resolve bank transaction subscriptions input data model',
  $schema: 'http://json-schema.org/draft-07/schema#',
};

export const resolveBankPaymentSubscriptionInput = {
  type: 'object',
  properties: {
    account: { type: 'string', title: 'Account number in IBAN -format' },
  },
  required: ['account'],
  title: 'Resolve bank payment subscription input data model',
  $schema: 'http://json-schema.org/draft-07/schema#',
};

export const resolveBankPaymentSubscriptionOutput = {
  type: 'object',
  properties: {
    subscription: {
      type: 'object',
      properties: {
        id: { type: 'string' },
        app: {
          type: 'object',
          properties: {
            name: {
              type: 'string',
              const: 'payment-integration-app',
              title: 'Application name',
            },
            configuration: {
              type: 'object',
              properties: {
                connections: {
                  type: 'object',
                  properties: {
                    erp: {
                      type: 'string',
                      const: 'netsuite',
                      title: 'Used ERP',
                    },
                    bank: {
                      type: 'string',
                      const: 'osuuspankki',
                      title: 'Used bank',
                    },
                  },
                  required: ['erp', 'bank'],
                  title: 'System connections',
                },
                subsidiary: {
                  type: 'object',
                  properties: {
                    subsidiaryId: {
                      type: 'string',
                      title: 'Identifier for the subsidiary',
                    },
                  },
                  required: ['subsidiaryId'],
                  title: 'ERP subsidiary details',
                },
                bank: {
                  type: 'object',
                  properties: {
                    name: { type: 'string', title: 'Bank name' },
                    addressLine1: {
                      type: 'string',
                      title: 'Street address line 1',
                    },
                    addressLine2: {
                      type: 'string',
                      title: 'Street address line 2',
                    },
                    countryCode: {
                      type: 'string',
                      title: 'Country code in ISO-3166 2-letters',
                    },
                  },
                  required: ['name', 'addressLine1', 'countryCode'],
                  title: 'Bank details',
                },
                account: {
                  type: 'object',
                  properties: {
                    iban: {
                      type: 'string',
                      title: "Subsidiary's bank account number in IBAN -format",
                      description:
                        'This is used to match incoming payments to configurations',
                    },
                    bic: {
                      type: 'string',
                      title:
                        "Subsidiary's bank identification code in BIC -format",
                    },
                    routingNumber: {
                      type: 'string',
                      title: 'The unique identification id for the bank',
                    },
                    organisationIdentification: {
                      type: 'string',
                      title: 'The unique service number issued by the bank',
                    },
                  },
                  required: ['iban', 'bic'],
                  title: 'Bank account details',
                },
              },
              required: ['connections', 'account'],
            },
          },
          required: ['configuration', 'name'],
        },
        status: {
          type: 'string',
          enum: [
            'Created',
            'Configuring',
            'Configured',
            'ConfigurationFailed',
            'Active',
            'Cancelling',
            'Cancelled',
            'Deleted',
          ],
          title: 'Internally used status enum for subscriptions',
        },
        subscriptionDetails: {
          type: 'object',
          properties: {
            name: {
              type: 'string',
              description: 'User-definable name for the integration',
            },
            disabled: { type: 'boolean' },
            startDate: { type: 'string' },
            endDate: { type: 'string' },
            createdDate: { type: 'string' },
            updatedDate: { type: 'string' },
          },
          required: ['startDate', 'createdDate', 'updatedDate'],
        },
      },
      required: ['id', 'app', 'status', 'subscriptionDetails'],
      title:
        'Enabled subscription matching the given account - undefined if not found',
    },
  },
  title: 'Resolve bank payment subscriptions input data model',
  $schema: 'http://json-schema.org/draft-07/schema#',
};

export const queryEInvoicesFromErpOutput = {
  type: 'object',
  properties: {
    invoiceIds: {
      type: 'array',
      items: {
        type: 'object',
        properties: { id: { type: 'string' } },
        required: ['id'],
      },
      title: 'Invoice ids',
    },
  },
  required: ['invoiceIds'],
  title: 'Query e-invoices ready for sending output data model',
  $schema: 'http://json-schema.org/draft-07/schema#',
};

export const getEInvoiceInput = {
  type: 'object',
  properties: { id: { type: 'string', title: 'Invoice id' } },
  required: ['id'],
  title: 'Get e-invoice input data model',
  $schema: 'http://json-schema.org/draft-07/schema#',
};

export const getEInvoiceOutput = {
  type: 'object',
  properties: {
    invoice: {
      type: 'object',
      properties: {
        id: { type: 'string' },
        payerEdiNumber: { type: 'string', deprecated: 'is this used at all?' },
        referenceNumber: { type: 'string' },
        invoiceNumber: { type: 'string' },
        buyerReferenceNumber: { type: 'string' },
        date: { type: 'string' },
        dueDate: { type: 'string' },
        currencyCode: { type: 'string' },
        customer: {
          type: 'object',
          properties: {
            id: { type: 'string', title: 'Customer id' },
            routingIdType: {
              type: 'string',
              enum: ['businessId', 'vatNumber', 'eInvoiceId'],
              title: 'Routing id to use',
            },
            name: { type: 'string', title: 'Customer name' },
            businessId: { type: 'string', title: 'Customer business id' },
            eInvoiceId: { type: 'string', title: 'Customer e-invoice id' },
            vatNumber: { type: 'string', title: 'Customer vat number' },
            address: {
              type: 'object',
              properties: {
                streetAddress1: { type: 'string', title: 'Street address' },
                streetAddress2: { type: 'string', title: 'Street address 2' },
                city: { type: 'string', title: 'City' },
                postalCode: { type: 'string', title: 'Postal code' },
                countryCode: { type: 'string', title: 'Country code' },
              },
              required: ['streetAddress1', 'city', 'postalCode', 'countryCode'],
              title: 'Customer address',
            },
          },
          required: ['id', 'routingIdType', 'name', 'vatNumber', 'address'],
          title: 'Customer',
        },
        subsidiary: {
          type: 'object',
          properties: {
            id: { type: 'string', title: 'Subsidiary id' },
            routingIdType: {
              type: 'string',
              enum: ['businessId', 'vatNumber', 'eInvoiceId'],
              title: 'Routing id to use',
            },
            name: { type: 'string', title: 'Subsidiary name' },
            businessId: { type: 'string', title: 'Subsidiary business id' },
            eInvoiceId: { type: 'string', title: 'Subsidiary e-invoice id' },
            vatNumber: { type: 'string', title: 'Subsidiary vat number' },
            address: {
              type: 'object',
              properties: {
                streetAddress1: { type: 'string', title: 'Street address' },
                streetAddress2: { type: 'string', title: 'Street address 2' },
                city: { type: 'string', title: 'City' },
                postalCode: { type: 'string', title: 'Postal code' },
                countryCode: { type: 'string', title: 'Country code' },
              },
              required: ['streetAddress1', 'city', 'postalCode', 'countryCode'],
              title: 'Subsidiary address',
            },
            bankAccount: {
              type: 'object',
              properties: {
                bic: { type: 'string', title: 'BIC' },
                iban: { type: 'string', title: 'IBAN' },
              },
              required: ['bic', 'iban'],
              title: 'Used bank account',
            },
          },
          required: [
            'id',
            'routingIdType',
            'name',
            'vatNumber',
            'address',
            'bankAccount',
          ],
          title: 'Subsidiary',
        },
        lineItems: {
          type: 'array',
          items: {
            type: 'object',
            properties: {
              lineNumber: { type: 'number' },
              netAmount: { type: 'number' },
              taxAmount: { type: 'number' },
              taxPercentage: { type: 'number' },
              taxCategoryCode: { type: 'string' },
              item: {
                type: 'object',
                properties: {
                  name: { type: 'string' },
                  quantity: { type: 'number' },
                  description: { type: 'string' },
                  netPrice: { type: 'number' },
                },
                required: ['name', 'quantity', 'netPrice'],
              },
            },
            required: [
              'lineNumber',
              'netAmount',
              'taxAmount',
              'taxPercentage',
              'taxCategoryCode',
              'item',
            ],
            title: 'Subsidiary',
          },
        },
        attachments: {
          type: 'array',
          items: {
            type: 'object',
            properties: {
              id: { type: 'string' },
              name: { type: 'string' },
              content: { type: 'array', items: { type: 'number' } },
            },
            required: ['id', 'name', 'content'],
          },
        },
      },
      required: [
        'id',
        'referenceNumber',
        'date',
        'dueDate',
        'currencyCode',
        'customer',
        'subsidiary',
        'lineItems',
        'attachments',
      ],
      title: 'Invoice',
    },
  },
  required: ['invoice'],
  title: 'Get e-invoice output data model',
  $schema: 'http://json-schema.org/draft-07/schema#',
};

export const sendEInvoiceInput = {
  type: 'object',
  properties: {
    invoice: {
      type: 'object',
      properties: {
        id: { type: 'string' },
        payerEdiNumber: { type: 'string', deprecated: 'is this used at all?' },
        referenceNumber: { type: 'string' },
        invoiceNumber: { type: 'string' },
        buyerReferenceNumber: { type: 'string' },
        date: { type: 'string' },
        dueDate: { type: 'string' },
        currencyCode: { type: 'string' },
        customer: {
          type: 'object',
          properties: {
            id: { type: 'string', title: 'Customer id' },
            routingIdType: {
              type: 'string',
              enum: ['businessId', 'vatNumber', 'eInvoiceId'],
              title: 'Routing id to use',
            },
            name: { type: 'string', title: 'Customer name' },
            businessId: { type: 'string', title: 'Customer business id' },
            eInvoiceId: { type: 'string', title: 'Customer e-invoice id' },
            vatNumber: { type: 'string', title: 'Customer vat number' },
            address: {
              type: 'object',
              properties: {
                streetAddress1: { type: 'string', title: 'Street address' },
                streetAddress2: { type: 'string', title: 'Street address 2' },
                city: { type: 'string', title: 'City' },
                postalCode: { type: 'string', title: 'Postal code' },
                countryCode: { type: 'string', title: 'Country code' },
              },
              required: ['streetAddress1', 'city', 'postalCode', 'countryCode'],
              title: 'Customer address',
            },
          },
          required: ['id', 'routingIdType', 'name', 'vatNumber', 'address'],
          title: 'Customer',
        },
        subsidiary: {
          type: 'object',
          properties: {
            id: { type: 'string', title: 'Subsidiary id' },
            routingIdType: {
              type: 'string',
              enum: ['businessId', 'vatNumber', 'eInvoiceId'],
              title: 'Routing id to use',
            },
            name: { type: 'string', title: 'Subsidiary name' },
            businessId: { type: 'string', title: 'Subsidiary business id' },
            eInvoiceId: { type: 'string', title: 'Subsidiary e-invoice id' },
            vatNumber: { type: 'string', title: 'Subsidiary vat number' },
            address: {
              type: 'object',
              properties: {
                streetAddress1: { type: 'string', title: 'Street address' },
                streetAddress2: { type: 'string', title: 'Street address 2' },
                city: { type: 'string', title: 'City' },
                postalCode: { type: 'string', title: 'Postal code' },
                countryCode: { type: 'string', title: 'Country code' },
              },
              required: ['streetAddress1', 'city', 'postalCode', 'countryCode'],
              title: 'Subsidiary address',
            },
            bankAccount: {
              type: 'object',
              properties: {
                bic: { type: 'string', title: 'BIC' },
                iban: { type: 'string', title: 'IBAN' },
              },
              required: ['bic', 'iban'],
              title: 'Used bank account',
            },
          },
          required: [
            'id',
            'routingIdType',
            'name',
            'vatNumber',
            'address',
            'bankAccount',
          ],
          title: 'Subsidiary',
        },
        lineItems: {
          type: 'array',
          items: {
            type: 'object',
            properties: {
              lineNumber: { type: 'number' },
              netAmount: { type: 'number' },
              taxAmount: { type: 'number' },
              taxPercentage: { type: 'number' },
              taxCategoryCode: { type: 'string' },
              item: {
                type: 'object',
                properties: {
                  name: { type: 'string' },
                  quantity: { type: 'number' },
                  description: { type: 'string' },
                  netPrice: { type: 'number' },
                },
                required: ['name', 'quantity', 'netPrice'],
              },
            },
            required: [
              'lineNumber',
              'netAmount',
              'taxAmount',
              'taxPercentage',
              'taxCategoryCode',
              'item',
            ],
            title: 'Subsidiary',
          },
        },
        attachments: {
          type: 'array',
          items: {
            type: 'object',
            properties: {
              id: { type: 'string' },
              name: { type: 'string' },
              content: { type: 'array', items: { type: 'number' } },
            },
            required: ['id', 'name', 'content'],
          },
        },
      },
      required: [
        'id',
        'referenceNumber',
        'date',
        'dueDate',
        'currencyCode',
        'customer',
        'subsidiary',
        'lineItems',
        'attachments',
      ],
      title: 'Invoice',
    },
  },
  required: ['invoice'],
  title: 'Send e-invoice input data model',
  $schema: 'http://json-schema.org/draft-07/schema#',
};

export const sendEInvoiceOutput = {
  type: 'object',
  properties: {
    operatorId: { type: 'string' },
    senderReference: { type: 'string' },
  },
  required: ['operatorId'],
  title: 'Send e-invoice output data model',
  $schema: 'http://json-schema.org/draft-07/schema#',
};

export const eInvoiceOperationConfiguration = {
  type: 'object',
  properties: {
    sendMode: {
      type: 'string',
      enum: ['Production', 'Test', 'Certification'],
      title: "Send mode - default is 'Certification'",
    },
  },
  title: 'E-invoice operation configuration',
  $schema: 'http://json-schema.org/draft-07/schema#',
};

export const queryFailedEInvoicesOutput = {
  type: 'object',
  properties: {
    invoices: {
      type: 'array',
      items: {
        type: 'object',
        properties: {
          operatorId: { type: 'string' },
          operatorFileId: { type: 'string' },
          error: {
            type: 'object',
            properties: {
              description: { type: 'string' },
              documentErrors: {
                type: 'array',
                items: {
                  type: 'object',
                  properties: {
                    title: { type: 'string' },
                    description: { type: 'string' },
                    errorValue: { type: 'string' },
                    errorReference: { type: 'string' },
                  },
                  required: [
                    'title',
                    'description',
                    'errorValue',
                    'errorReference',
                  ],
                },
              },
            },
            required: ['description', 'documentErrors'],
          },
        },
        required: ['operatorId', 'operatorFileId', 'error'],
      },
    },
  },
  required: ['invoices'],
  title: 'Query failed e-invoices output data model',
  $schema: 'http://json-schema.org/draft-07/schema#',
};

export const handleFailedEInvoiceInput = {
  type: 'object',
  properties: {
    invoice: {
      type: 'object',
      properties: {
        operatorId: { type: 'string' },
        operatorFileId: { type: 'string' },
        error: {
          type: 'object',
          properties: {
            description: { type: 'string' },
            documentErrors: {
              type: 'array',
              items: {
                type: 'object',
                properties: {
                  title: { type: 'string' },
                  description: { type: 'string' },
                  errorValue: { type: 'string' },
                  errorReference: { type: 'string' },
                },
                required: [
                  'title',
                  'description',
                  'errorValue',
                  'errorReference',
                ],
              },
            },
          },
          required: ['description', 'documentErrors'],
        },
      },
      required: ['operatorId', 'operatorFileId', 'error'],
      title: 'Invoice',
    },
  },
  required: ['invoice'],
  title: 'Handle failed e-invoice input data model',
  $schema: 'http://json-schema.org/draft-07/schema#',
};

export const handleFailedEInvoiceOutput = {
  type: 'object',
  properties: {
    invoice: {
      type: 'object',
      properties: {
        operatorId: { type: 'string' },
        operatorFileId: { type: 'string' },
        error: {
          type: 'object',
          properties: {
            description: { type: 'string' },
            documentErrors: {
              type: 'array',
              items: {
                type: 'object',
                properties: {
                  title: { type: 'string' },
                  description: { type: 'string' },
                  errorValue: { type: 'string' },
                  errorReference: { type: 'string' },
                },
                required: [
                  'title',
                  'description',
                  'errorValue',
                  'errorReference',
                ],
              },
            },
          },
          required: ['description', 'documentErrors'],
        },
      },
      required: ['operatorId', 'operatorFileId', 'error'],
      title: 'Failed invoice',
    },
    originalInvoice: {
      type: 'object',
      properties: {
        id: { type: 'string' },
        payerEdiNumber: { type: 'string', deprecated: 'is this used at all?' },
        referenceNumber: { type: 'string' },
        invoiceNumber: { type: 'string' },
        buyerReferenceNumber: { type: 'string' },
        date: { type: 'string' },
        dueDate: { type: 'string' },
        currencyCode: { type: 'string' },
        customer: {
          type: 'object',
          properties: {
            id: { type: 'string', title: 'Customer id' },
            routingIdType: {
              type: 'string',
              enum: ['businessId', 'vatNumber', 'eInvoiceId'],
              title: 'Routing id to use',
            },
            name: { type: 'string', title: 'Customer name' },
            businessId: { type: 'string', title: 'Customer business id' },
            eInvoiceId: { type: 'string', title: 'Customer e-invoice id' },
            vatNumber: { type: 'string', title: 'Customer vat number' },
            address: {
              type: 'object',
              properties: {
                streetAddress1: { type: 'string', title: 'Street address' },
                streetAddress2: { type: 'string', title: 'Street address 2' },
                city: { type: 'string', title: 'City' },
                postalCode: { type: 'string', title: 'Postal code' },
                countryCode: { type: 'string', title: 'Country code' },
              },
              required: ['streetAddress1', 'city', 'postalCode', 'countryCode'],
              title: 'Customer address',
            },
          },
          required: ['id', 'routingIdType', 'name', 'vatNumber', 'address'],
          title: 'Customer',
        },
        subsidiary: {
          type: 'object',
          properties: {
            id: { type: 'string', title: 'Subsidiary id' },
            routingIdType: {
              type: 'string',
              enum: ['businessId', 'vatNumber', 'eInvoiceId'],
              title: 'Routing id to use',
            },
            name: { type: 'string', title: 'Subsidiary name' },
            businessId: { type: 'string', title: 'Subsidiary business id' },
            eInvoiceId: { type: 'string', title: 'Subsidiary e-invoice id' },
            vatNumber: { type: 'string', title: 'Subsidiary vat number' },
            address: {
              type: 'object',
              properties: {
                streetAddress1: { type: 'string', title: 'Street address' },
                streetAddress2: { type: 'string', title: 'Street address 2' },
                city: { type: 'string', title: 'City' },
                postalCode: { type: 'string', title: 'Postal code' },
                countryCode: { type: 'string', title: 'Country code' },
              },
              required: ['streetAddress1', 'city', 'postalCode', 'countryCode'],
              title: 'Subsidiary address',
            },
            bankAccount: {
              type: 'object',
              properties: {
                bic: { type: 'string', title: 'BIC' },
                iban: { type: 'string', title: 'IBAN' },
              },
              required: ['bic', 'iban'],
              title: 'Used bank account',
            },
          },
          required: [
            'id',
            'routingIdType',
            'name',
            'vatNumber',
            'address',
            'bankAccount',
          ],
          title: 'Subsidiary',
        },
        lineItems: {
          type: 'array',
          items: {
            type: 'object',
            properties: {
              lineNumber: { type: 'number' },
              netAmount: { type: 'number' },
              taxAmount: { type: 'number' },
              taxPercentage: { type: 'number' },
              taxCategoryCode: { type: 'string' },
              item: {
                type: 'object',
                properties: {
                  name: { type: 'string' },
                  quantity: { type: 'number' },
                  description: { type: 'string' },
                  netPrice: { type: 'number' },
                },
                required: ['name', 'quantity', 'netPrice'],
              },
            },
            required: [
              'lineNumber',
              'netAmount',
              'taxAmount',
              'taxPercentage',
              'taxCategoryCode',
              'item',
            ],
            title: 'Subsidiary',
          },
        },
      },
      required: [
        'id',
        'referenceNumber',
        'date',
        'dueDate',
        'currencyCode',
        'customer',
        'subsidiary',
        'lineItems',
      ],
      title: 'Original invoice data',
    },
  },
  required: ['invoice', 'originalInvoice'],
  title: 'Handle failed e-invoice input data model',
  $schema: 'http://json-schema.org/draft-07/schema#',
};

export const queryIncomingEInvoicesOutput = {
  type: 'object',
  properties: {
    ids: {
      type: 'array',
      items: {
        type: 'object',
        properties: { id: { type: 'string' } },
        required: ['id'],
      },
      title: 'Document ids',
    },
  },
  required: ['ids'],
  title: 'Query e-invoices ready for receiving output data model',
  $schema: 'http://json-schema.org/draft-07/schema#',
};

export const receiveIncomingEInvoiceInput = {
  type: 'object',
  properties: { id: { type: 'string', title: 'Document id' } },
  required: ['id'],
  title: 'Receive e-invoice from Pagero output data model',
  $schema: 'http://json-schema.org/draft-07/schema#',
};

export const receiveIncomingEInvoiceOutput = {
  type: 'object',
  properties: {
    invoice: {
      type: 'object',
      properties: {
        id: { type: 'string' },
        identifier: { type: 'string' },
        date: { type: 'string' },
        dueDate: { type: 'string' },
        currencyCode: { type: 'string' },
        netAmount: { type: 'number' },
        vatAmount: { type: 'number' },
        totalAmount: { type: 'number' },
        sender: {
          type: 'object',
          properties: {
            id: { type: 'string', title: 'Customer / Supplier number' },
            name: { type: 'string', title: 'Customer name' },
            businessId: { type: 'string', title: 'Customer business id' },
            eInvoiceId: { type: 'string', title: 'Customer e-invoice id' },
            vatNumber: { type: 'string', title: 'Customer vat number' },
            address: {
              type: 'object',
              properties: {
                streetAddress1: { type: 'string', title: 'Street address' },
                streetAddress2: { type: 'string', title: 'Street address 2' },
                city: { type: 'string', title: 'City' },
                postalCode: { type: 'string', title: 'Postal code' },
                countryCode: { type: 'string', title: 'Country code' },
              },
              required: ['streetAddress1', 'city', 'postalCode', 'countryCode'],
              title: 'Customer address',
            },
          },
          required: ['id', 'name', 'vatNumber', 'address'],
        },
        receiver: {
          type: 'object',
          properties: {
            id: { type: 'string', title: 'Customer / Supplier number' },
            name: { type: 'string', title: 'Customer name' },
            businessId: { type: 'string', title: 'Customer business id' },
            eInvoiceId: { type: 'string', title: 'Customer e-invoice id' },
            vatNumber: { type: 'string', title: 'Customer vat number' },
            address: {
              type: 'object',
              properties: {
                streetAddress1: { type: 'string', title: 'Street address' },
                streetAddress2: { type: 'string', title: 'Street address 2' },
                city: { type: 'string', title: 'City' },
                postalCode: { type: 'string', title: 'Postal code' },
                countryCode: { type: 'string', title: 'Country code' },
              },
              required: ['streetAddress1', 'city', 'postalCode', 'countryCode'],
              title: 'Customer address',
            },
          },
          required: ['id', 'name', 'vatNumber', 'address'],
        },
        presentationDocument: {
          type: 'object',
          properties: {
            id: { type: 'string' },
            name: { type: 'string' },
            mimeType: { type: 'string' },
            content: { type: 'array', items: { type: 'number' } },
          },
          required: ['id', 'name', 'mimeType', 'content'],
        },
        legalDocument: {
          type: 'object',
          properties: {
            id: { type: 'string' },
            name: { type: 'string' },
            mimeType: { type: 'string' },
            content: { type: 'array', items: { type: 'number' } },
          },
          required: ['id', 'name', 'mimeType', 'content'],
        },
      },
      required: [
        'id',
        'identifier',
        'date',
        'dueDate',
        'currencyCode',
        'netAmount',
        'vatAmount',
        'totalAmount',
        'sender',
        'receiver',
      ],
      title: 'Invoice',
    },
  },
  required: ['invoice'],
  title: 'Receive e-invoice from Pagero output data model',
  $schema: 'http://json-schema.org/draft-07/schema#',
};

export const handleErpPaymentInput = {
  type: 'object',
  properties: {
    payment: {
      type: 'object',
      properties: {
        payment: {
          type: 'object',
          properties: {
            reference: { type: 'string' },
            internalId: { type: 'string' },
            amount: { type: 'number' },
            bank: {
              type: 'object',
              properties: { bic: { type: 'string' }, iban: { type: 'string' } },
              required: ['bic', 'iban'],
            },
            dueDate: { type: 'string' },
            message: { type: 'string' },
            templateId: { type: 'string' },
            currencyCode: { type: 'string' },
            transactionId: { type: 'string' },
          },
          required: [
            'internalId',
            'amount',
            'bank',
            'dueDate',
            'currencyCode',
            'transactionId',
          ],
        },
        subsidiary: {
          type: 'object',
          properties: {
            name: { type: 'string' },
            address: {
              type: 'object',
              properties: {
                city: { type: 'string' },
                countryCode: { type: 'string' },
                line: { type: 'string' },
                postalCode: { type: 'string' },
                streetAddress1: { type: 'string' },
                streetAddress2: { type: 'string' },
                stateCode: { type: 'string' },
              },
              required: ['city', 'countryCode', 'postalCode', 'streetAddress1'],
            },
          },
          required: ['name', 'address'],
        },
        vendor: {
          type: 'object',
          properties: {
            name: { type: 'string' },
            internalId: { type: 'string' },
            bank: {
              type: 'object',
              properties: { bic: { type: 'string' }, iban: { type: 'string' } },
              required: ['bic', 'iban'],
            },
            address: {
              type: 'object',
              properties: {
                city: { type: 'string' },
                countryCode: { type: 'string' },
                line: { type: 'string' },
                postalCode: { type: 'string' },
                streetAddress1: { type: 'string' },
                streetAddress2: { type: 'string' },
                stateCode: { type: 'string' },
              },
              required: ['city', 'countryCode', 'postalCode', 'streetAddress1'],
            },
          },
          required: ['name', 'internalId', 'bank', 'address'],
        },
      },
      required: ['payment', 'subsidiary', 'vendor'],
    },
  },
  required: ['payment'],
  title: 'Handle ERP payment input data model',
  $schema: 'http://json-schema.org/draft-07/schema#',
};

export const handleErpPaymentOutput = {
  type: 'object',
  properties: {
    internalId: { type: 'string', title: 'Payment id in dynamodb' },
    status: {
      type: 'string',
      enum: [
        'SubscriptionCheckFailed',
        'ValidationFailed',
        'Received',
        'ReceiveFailed',
        'Updated',
        'UpdateFailed',
        'SentForConversion',
        'ConversionFailed',
        'Converted',
        'ReadyForBank',
        'SentToBank',
        'BankPaymentStatusFailed',
        'BankPaymentFailed',
        'BankPaymentInProcessing',
        'BankPaymentProcessed',
      ],
      title: 'Payment status',
    },
    sendToBank: {
      type: 'boolean',
      title: 'Should we send the payment to bank right away',
    },
  },
  required: ['internalId', 'status', 'sendToBank'],
  title: 'Handle ERP payment output data model',
  $schema: 'http://json-schema.org/draft-07/schema#',
};

export const handlePaymentErrorInput = {
  type: 'object',
  properties: {
    erpId: { type: 'string' },
    id: { type: 'string' },
    paymentStatus: {
      type: 'string',
      enum: [
        'SubscriptionCheckFailed',
        'ValidationFailed',
        'Received',
        'ReceiveFailed',
        'Updated',
        'UpdateFailed',
        'SentForConversion',
        'ConversionFailed',
        'Converted',
        'ReadyForBank',
        'SentToBank',
        'BankPaymentStatusFailed',
        'BankPaymentFailed',
        'BankPaymentInProcessing',
        'BankPaymentProcessed',
      ],
    },
  },
  required: ['paymentStatus'],
  title: 'Handle payment error input data model',
  $schema: 'http://json-schema.org/draft-07/schema#',
};

export const listPaymentsInput = {
  type: 'object',
  properties: {
    status: {
      type: 'string',
      enum: [
        'SubscriptionCheckFailed',
        'ValidationFailed',
        'Received',
        'ReceiveFailed',
        'Updated',
        'UpdateFailed',
        'SentForConversion',
        'ConversionFailed',
        'Converted',
        'ReadyForBank',
        'SentToBank',
        'BankPaymentStatusFailed',
        'BankPaymentFailed',
        'BankPaymentInProcessing',
        'BankPaymentProcessed',
      ],
      title: 'Payment status',
    },
  },
  title: 'List payments input data model',
  $schema: 'http://json-schema.org/draft-07/schema#',
};

export const listPaymentsOutput = {
  type: 'object',
  properties: {
    payments: {
      type: 'array',
      items: {
        type: 'object',
        properties: {
          payment: {
            type: 'object',
            properties: {
              internalId: { type: 'string' },
              statusHistory: {
                type: 'array',
                items: {
                  type: 'object',
                  properties: {
                    action: {
                      type: 'object',
                      properties: {
                        tenantId: { type: 'string' },
                        actionId: { type: 'string' },
                        integrationId: { type: 'string' },
                      },
                      required: ['tenantId', 'integrationId'],
                    },
                    recordStatus: {
                      type: 'string',
                      enum: [
                        'SubscriptionCheckFailed',
                        'ValidationFailed',
                        'Received',
                        'ReceiveFailed',
                        'Updated',
                        'UpdateFailed',
                        'SentForConversion',
                        'ConversionFailed',
                        'Converted',
                        'ReadyForBank',
                        'SentToBank',
                        'BankPaymentStatusFailed',
                        'BankPaymentFailed',
                        'BankPaymentInProcessing',
                        'BankPaymentProcessed',
                      ],
                    },
                    error: {},
                    created: { type: 'string' },
                  },
                  required: ['recordStatus', 'created'],
                },
              },
              created: { type: 'string' },
              paymentRecord: {
                type: 'object',
                properties: {
                  payment: {
                    type: 'object',
                    properties: {
                      reference: { type: 'string' },
                      internalId: { type: 'string' },
                      amount: { type: 'number' },
                      bank: {
                        type: 'object',
                        properties: {
                          bic: { type: 'string' },
                          iban: { type: 'string' },
                        },
                        required: ['bic', 'iban'],
                      },
                      dueDate: { type: 'string' },
                      message: { type: 'string' },
                      templateId: { type: 'string' },
                      currencyCode: { type: 'string' },
                      transactionId: { type: 'string' },
                    },
                    required: [
                      'internalId',
                      'amount',
                      'bank',
                      'dueDate',
                      'currencyCode',
                      'transactionId',
                    ],
                  },
                  subsidiary: {
                    type: 'object',
                    properties: {
                      name: { type: 'string' },
                      address: {
                        type: 'object',
                        properties: {
                          city: { type: 'string' },
                          countryCode: { type: 'string' },
                          line: { type: 'string' },
                          postalCode: { type: 'string' },
                          streetAddress1: { type: 'string' },
                          streetAddress2: { type: 'string' },
                          stateCode: { type: 'string' },
                        },
                        required: [
                          'city',
                          'countryCode',
                          'postalCode',
                          'streetAddress1',
                        ],
                      },
                    },
                    required: ['name', 'address'],
                  },
                  vendor: {
                    type: 'object',
                    properties: {
                      name: { type: 'string' },
                      internalId: { type: 'string' },
                      bank: {
                        type: 'object',
                        properties: {
                          bic: { type: 'string' },
                          iban: { type: 'string' },
                        },
                        required: ['bic', 'iban'],
                      },
                      address: {
                        type: 'object',
                        properties: {
                          city: { type: 'string' },
                          countryCode: { type: 'string' },
                          line: { type: 'string' },
                          postalCode: { type: 'string' },
                          streetAddress1: { type: 'string' },
                          streetAddress2: { type: 'string' },
                          stateCode: { type: 'string' },
                        },
                        required: [
                          'city',
                          'countryCode',
                          'postalCode',
                          'streetAddress1',
                        ],
                      },
                    },
                    required: ['name', 'internalId', 'bank', 'address'],
                  },
                },
                required: ['payment', 'subsidiary', 'vendor'],
              },
              tenantId: { type: 'string' },
              externalId: { type: 'string' },
              actionId: { type: 'string' },
              modified: { type: 'string' },
              subscription: {
                type: 'object',
                properties: {
                  subscriptionId: { type: 'string' },
                  connections: {
                    type: 'object',
                    properties: {
                      bank: { type: 'string' },
                      erp: { type: 'string' },
                    },
                    required: ['bank', 'erp'],
                  },
                },
                required: ['subscriptionId', 'connections'],
              },
              paymentStatus: {
                type: 'string',
                enum: [
                  'SubscriptionCheckFailed',
                  'ValidationFailed',
                  'Received',
                  'ReceiveFailed',
                  'Updated',
                  'UpdateFailed',
                  'SentForConversion',
                  'ConversionFailed',
                  'Converted',
                  'ReadyForBank',
                  'SentToBank',
                  'BankPaymentStatusFailed',
                  'BankPaymentFailed',
                  'BankPaymentInProcessing',
                  'BankPaymentProcessed',
                ],
              },
            },
            required: [
              'internalId',
              'created',
              'paymentRecord',
              'tenantId',
              'externalId',
              'modified',
              'subscription',
              'paymentStatus',
            ],
          },
          sendToBank: { type: 'boolean' },
        },
        required: ['payment', 'sendToBank'],
      },
      title: 'Payments',
    },
  },
  required: ['payments'],
  title: 'List payments output data model',
  $schema: 'http://json-schema.org/draft-07/schema#',
};

export const queryPaymentsFromErpOutput = {
  type: 'object',
  properties: {
    payments: {
      type: 'array',
      items: {
        type: 'object',
        properties: {
          payment: {
            type: 'object',
            properties: {
              reference: { type: 'string' },
              internalId: { type: 'string' },
              amount: { type: 'number' },
              bank: {
                type: 'object',
                properties: {
                  bic: { type: 'string' },
                  iban: { type: 'string' },
                },
                required: ['bic', 'iban'],
              },
              dueDate: { type: 'string' },
              message: { type: 'string' },
              templateId: { type: 'string' },
              currencyCode: { type: 'string' },
              transactionId: { type: 'string' },
            },
            required: [
              'internalId',
              'amount',
              'bank',
              'dueDate',
              'currencyCode',
              'transactionId',
            ],
          },
          subsidiary: {
            type: 'object',
            properties: {
              name: { type: 'string' },
              address: {
                type: 'object',
                properties: {
                  city: { type: 'string' },
                  countryCode: { type: 'string' },
                  line: { type: 'string' },
                  postalCode: { type: 'string' },
                  streetAddress1: { type: 'string' },
                  streetAddress2: { type: 'string' },
                  stateCode: { type: 'string' },
                },
                required: [
                  'city',
                  'countryCode',
                  'postalCode',
                  'streetAddress1',
                ],
              },
            },
            required: ['name', 'address'],
          },
          vendor: {
            type: 'object',
            properties: {
              name: { type: 'string' },
              internalId: { type: 'string' },
              bank: {
                type: 'object',
                properties: {
                  bic: { type: 'string' },
                  iban: { type: 'string' },
                },
                required: ['bic', 'iban'],
              },
              address: {
                type: 'object',
                properties: {
                  city: { type: 'string' },
                  countryCode: { type: 'string' },
                  line: { type: 'string' },
                  postalCode: { type: 'string' },
                  streetAddress1: { type: 'string' },
                  streetAddress2: { type: 'string' },
                  stateCode: { type: 'string' },
                },
                required: [
                  'city',
                  'countryCode',
                  'postalCode',
                  'streetAddress1',
                ],
              },
            },
            required: ['name', 'internalId', 'bank', 'address'],
          },
        },
        required: ['payment', 'subsidiary', 'vendor'],
      },
      title: 'Payment records',
    },
  },
  required: ['payments'],
  title: 'Query payments from ERP output data model',
  $schema: 'http://json-schema.org/draft-07/schema#',
};

export const createBankPaymentInput = {
  type: 'object',
  properties: {
    id: { type: 'string', title: "Payment's internal id in DynamoDb" },
    erpId: { type: 'string', title: "Payment's internal id in ERP" },
  },
  title: 'Create bank payment input data model',
  $schema: 'http://json-schema.org/draft-07/schema#',
};

export const createBankPaymentOutput = {
  type: 'object',
  properties: {
    status: {
      type: 'string',
      enum: ['Processing', 'Processed', 'Rejected'],
      title: 'Payment status',
    },
  },
  required: ['status'],
  title: 'Get bank payment status input data model',
  $schema: 'http://json-schema.org/draft-07/schema#',
};

export const getBankPaymentStatusInput = {
  type: 'object',
  properties: {
    id: { type: 'string', title: "Payment's internal id in DynamoDb" },
    erpId: { type: 'string', title: "Payment's internal id in ERP" },
  },
  title: 'Get bank payment status input data model',
  $schema: 'http://json-schema.org/draft-07/schema#',
};

export const getBankPaymentStatusOutput = {
  type: 'object',
  properties: {
    status: {
      type: 'string',
      enum: ['Processing', 'Processed', 'Rejected'],
      title: 'Payment status',
    },
  },
  required: ['status'],
  title: 'Get bank payment status input data model',
  $schema: 'http://json-schema.org/draft-07/schema#',
};
