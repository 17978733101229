import { SupportedModule } from '../../../integrations/src/interface';

export const bankConnections: SupportedModule[] = ['osuuspankki'];
export const erpConnections: SupportedModule[] = ['netsuite'];

// TODO: schema generation doesn't (yet) handle the typeof -solution
type SupportedBank = 'osuuspankki'; //(typeof bankConnections)[number];
type SupportedErp = 'netsuite'; //(typeof erpConnections)[number];

export interface PaymentIntegrationAppConfiguration {
  /**
   * @title System connections
   */
  connections: {
    /**
     * @title Used ERP
     */
    erp: SupportedErp;
    /**
     * @title Used bank
     */
    bank: SupportedBank;
  };
  /**
   * @title ERP subsidiary details
   */
  subsidiary?: {
    /**
     * @title Identifier for the subsidiary
     */
    subsidiaryId: string;
  };
  /**
   * @title Bank details
   */
  bank?: {
    /**
     * @title Bank name
     */
    name: string;
    /**
     * @title Street address line 1
     */
    addressLine1: string;
    /**
     * @title Street address line 2
     */
    addressLine2?: string;
    /**
     * @title Country code in ISO-3166 2-letters
     */
    countryCode: string;
  };
  /**
   * @title Bank account details
   */
  account: {
    /**
     * @title Subsidiary's bank account number in IBAN -format
     * @description This is used to match incoming payments to configurations
     */
    iban: string;
    /**
     * @title Subsidiary's bank identification code in BIC -format
     */
    bic: string;
    /**
     * @title The unique identification id for the bank
     */
    routingNumber?: string;
    /**
     * @title The unique service number issued by the bank
     */
    organisationIdentification?: string;
  };
}

export interface PaymentIntegrationConfigurationTrigger {
  subscriptionId: string;
  configuration: PaymentIntegrationAppConfiguration;
}
