import React, { JSX, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { defaultIntegrationDefinitionBase } from '@apus/common-lib/api/defaults/integration-service';
import {
  IntegrationDefinition,
  IntegrationDefinitionBase,
  IntegrationWorkflowDefinition,
} from '@apus/common-lib/api/interface/integration-service';
import { integrationDefinitionSchema } from '@apus/common-lib/api/schemas/integration-service';
import { IntegrationModule } from '@apus/common-lib/integrations/src/interface';
import useIntegrationService from '@apus/common-ui/hooks/useIntegrationService';
import { getResolver } from '@apus/common-ui/utils/data-utils';
import ContentPasteGoTwoToneIcon from '@mui/icons-material/ContentPasteGoTwoTone';
import { Alert, AlertColor, Box, Grid, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import { isEqual } from 'lodash';
import { v4 as uuid } from 'uuid';

import DefineWorkflow from './DefineWorkflow';
import IntegrationConfigurationForm from '../../integration/form/IntegrationConfigurationForm';
import { bodyMaxHeight } from '../../../theme/theme';

interface Props {
  modules: IntegrationModule[];
  integration?: IntegrationDefinition;
  integrations: IntegrationDefinition[];
  onSave?: (integration: IntegrationDefinition) => void;
}

type WorkingIntegrationDefinition = Pick<
  IntegrationDefinition,
  | 'integrationId'
  | 'name'
  | 'description'
  | 'configuration'
  | 'errorIntegration'
> &
  Pick<Partial<IntegrationDefinition>, 'workflow'>;

interface StatusAlert {
  severity: AlertColor;
  message: string;
}

const DefineIntegration = ({
  modules,
  integration,
  integrations,
  onSave,
}: Props): JSX.Element => {
  const integrationService = useIntegrationService();

  const [needsSaving, setNeedsSaving] = useState<boolean>(false);
  const [alert, setAlert] = useState<StatusAlert | undefined>();

  const detailsForm = useForm<IntegrationDefinitionBase>({
    resolver: getResolver<IntegrationDefinitionBase>({
      type: 'object',
      properties: {
        name: integrationDefinitionSchema.properties?.name,
        description: integrationDefinitionSchema.properties?.description,
        configuration: integrationDefinitionSchema.properties?.configuration,
      },
      required: ['name'],
    }),
    defaultValues: defaultIntegrationDefinitionBase,
  });

  const [workingIntegration, setWorkingIntegration] =
    useState<WorkingIntegrationDefinition>();

  useEffect(() => {
    if (integration === undefined) {
      setWorkingIntegration({
        description: '',
        integrationId: '',
        name: '',
      });
    } else {
      setWorkingIntegration(integration);
    }
  }, [integration, setWorkingIntegration]);

  function workflowUpdated(workflow: IntegrationWorkflowDefinition) {
    if (workingIntegration !== undefined) {
      setWorkingIntegration({
        ...workingIntegration,
        workflow,
      });

      setNeedsSaving(!isEqual(workflow, workingIntegration.workflow));
    }
  }

  function configurationUpdated() {
    if (workingIntegration !== undefined) {
      const values = detailsForm.getValues();
      const current: IntegrationDefinitionBase = {
        integrationId: values.integrationId,
        errorIntegration: values.errorIntegration,
        name: values.name,
        description: values.description,
        configuration: values.configuration,
      };

      setWorkingIntegration({ ...workingIntegration, ...current });
      setNeedsSaving(!isEqual(current, detailsForm.getValues()));
    }
  }

  async function paste() {
    const clipboard = await navigator.clipboard.readText();
    if (clipboard !== undefined && clipboard.trim() !== '') {
      const pasted = JSON.parse(clipboard);
      setWorkingIntegration({
        ...pasted,
        integrationId: uuid(),
      });
      setNeedsSaving(true);
    }
  }

  async function save(formData: IntegrationDefinitionBase) {
    if (
      workingIntegration === undefined ||
      workingIntegration.workflow === undefined
    )
      throw new Error(`Workflow in mandatory`);

    setAlert(undefined);

    const data: IntegrationDefinition = {
      configuration: formData.configuration,
      description: formData.description,
      name: formData.name,
      errorIntegration: formData.errorIntegration,
      integrationId: integration?.integrationId ?? uuid(),
      status: 'Stopped',
      workflow: workingIntegration.workflow,
      app: integration?.app,
    };

    try {
      await integrationService.defineIntegration(data.integrationId, data);
      setAlert({ severity: 'success', message: `Integration saved` });
      if (onSave !== undefined) onSave(data);
    } catch (e) {
      setAlert({ severity: 'error', message: `Integration save failed` });
    }
  }

  if (workingIntegration === undefined) return <></>;

  return (
    <Grid
      container
      spacing={1}
      sx={{ maxHeight: bodyMaxHeight, maxWidth: '100%', overflowY: 'scroll' }}
    >
      <Grid item xs={12}>
        {alert !== undefined && (
          <Alert severity={alert.severity}>{alert.message}</Alert>
        )}
      </Grid>
      <Grid item xs={6}>
        <Typography variant={'h6'}>Define integration</Typography>
      </Grid>
      <Grid item xs={6}>
        <Box flexGrow={1} flexDirection={'row-reverse'} display={'flex'}>
          <Button
            variant={'contained'}
            onClick={detailsForm.handleSubmit(save)}
            disabled={!needsSaving}
            sx={{ marginRight: 1 }}
          >
            Save
          </Button>
          <Button
            variant={'outlined'}
            onClick={paste}
            disabled={integration !== undefined}
            startIcon={<ContentPasteGoTwoToneIcon color={'warning'} />}
            sx={{ marginRight: 1 }}
          >
            Paste
          </Button>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <IntegrationConfigurationForm
          form={detailsForm}
          isNew={integration === undefined}
          integration={workingIntegration}
          onChange={configurationUpdated}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography variant={'h6'}>Workflow</Typography>
      </Grid>
      <Grid item xs={12}>
        <DefineWorkflow
          modules={modules}
          integrations={integrations}
          workflow={workingIntegration.workflow}
          onChange={workflowUpdated}
        />
      </Grid>
    </Grid>
  );
};

export default DefineIntegration;
