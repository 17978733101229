import { SourceObject } from '../../json-data-mapper/src/interface';
import { JSONSchema7 } from 'json-schema';
import {
  ApiResult,
  ApiResultStatus,
  EventContext,
  IntegrationOperation,
  SupportedModule,
} from '../../integrations/src/interface';
import {
  ApiEndpointTriggerNode,
  ErrorTriggerNode,
  IntegrationNode,
  IntegrationNodeType,
  PollingTriggerNode,
  WebhookTriggerNode,
} from '../../integration-engine/src/interface';
import { FullQueueAction } from '../../aws-action-queue/src/interface-public';
import { SNSClient } from '@aws-sdk/client-sns';
import { SystemglueAppName } from './common';
import { DynamoDBDocumentClient } from '@aws-sdk/lib-dynamodb';
import { S3Client } from '@aws-sdk/client-s3';
import { ExposedApi } from '@apus/common-lib/api/interface/exposed-api';

export type FileId = string;
export type ISODateTime = string;

export type IntegrationStatus = 'Stopped' | 'Paused' | 'Running';

export enum RetryStrategy {
  Fixed = 'Fixed',
}

export enum UserRole {
  PlatformAdmin = 'platform-admin-user',
  TenantAdmin = 'tenant-admin-user',
  TenantUser = 'tenant-user',
}

export interface AutomatedRetryConfiguration {
  /**
   * Is automatic retrying enabled or disabled
   */
  enabled: boolean;
  /**
   * Strategy to use
   *
   * Exponential backoff will increase the delay exponentially over time, Fixed will use the same delay throughout
   */
  strategy: RetryStrategy;
  /**
   * Delay in minutes
   *
   * For Exponential strategy, this will define only the initial delay
   */
  delay: number;
  /**
   * Number of retries
   */
  retries: number;
}

export interface DebuggingConfiguration {
  recordApiCalls?: boolean;
}

export interface ActionStorageConfiguration {
  /**
   * How many days will actions be stored? Default is -1 i.e. indefinitely
   */
  numberOfDaysToStore: number;
  storeContentOnlyInDynamodb?: boolean;
}

export interface GeneralConfiguration {
  storeFilesSeparately?: boolean;
}

export interface IntegrationConfiguration {
  retrying?: AutomatedRetryConfiguration;
  debugging?: DebuggingConfiguration;
  storage?: ActionStorageConfiguration;
  general?: GeneralConfiguration;
}

export type TriggerNode =
  | WebhookTriggerNode
  | PollingTriggerNode
  | ErrorTriggerNode
  | ApiEndpointTriggerNode;

export interface IntegrationWorkflowTestInput {
  mockedOutput?: unknown;
  expectation?: unknown;
}

export interface IntegrationWorkflowTest {
  readonly id: string;
  name: string;
  description?: string;
  simulateOnlyMocked?: boolean;
  trigger: IntegrationWorkflowTestInput;
  nodes: Record<string, IntegrationWorkflowTestInput>;
}

export interface IntegrationWorkflowDefinition<TRIGGER_TYPE = TriggerNode> {
  trigger: TRIGGER_TYPE;
  nodes: IntegrationNode[];
  tests?: IntegrationWorkflowTest[];
}

export interface IntegrationWorkflowTestResult {
  readonly id: string;
  name: string;
  status: IntegrationEventStatus;
  results: WorkflowNodeResult[];
}

export type ErrorHandlingWorkflowDefinition =
  IntegrationWorkflowDefinition<ErrorTriggerNode>;

export interface IntegrationDefinitionBase {
  integrationId: string;
  name: string;
  description?: string;
  errorIntegration?: IntegrationDefinitionReference;
  configuration?: IntegrationConfiguration;
}

export interface IntegrationDefinition extends IntegrationDefinitionBase {
  readonly status: IntegrationStatus;
  readonly allowedActions?: IntegrationControlAction[];
  isReadOnly?: boolean;
  app?: SystemglueAppName;
  version?: string;
  workflow: IntegrationWorkflowDefinition;
}

export type ModuleConfigurationAction = 'tenant-authorization';

export interface ModuleConfiguration {
  moduleId: SupportedModule;
  readonly enabled?: boolean;
  actionsNeeded?: ModuleConfigurationAction[];
  // TODO: check if needed
  readonly configurationSchema?: JSONSchema7;
  configuration: SourceObject;
}

export interface ConnectionStatus {
  readonly moduleId: SupportedModule;
  readonly enabled: boolean;
  readonly message?: string;
}

export interface CustomDataStorageSettings {
  s3: {
    bucketName: string;
  };
  dynamodb: {
    tableName: string;
    gsi1pkName: string;
    gsi2pkName: string;
    gsi3pkName: string;
  };
}

export interface EnvironmentConfiguration {
  eventBusArn: string;
  snsClient: SNSClient;
  dynamodbClient: DynamoDBDocumentClient;
  s3Client: S3Client;
  settings: {
    customDataStorage: CustomDataStorageSettings;
  };
}

export type FileTypeParameter =
  | 'source-schema'
  | 'target-schema'
  | 'mapping-schema';

export type IntegrationEventStatus =
  | 'Started'
  | 'Omitted'
  | 'Finished'
  | 'Error'
  | 'Pending';

export type HttpMethod =
  | 'GET'
  | 'PUT'
  | 'POST'
  | 'HEAD'
  | 'PATCH'
  | 'MERGE'
  | 'DELETE'
  | 'OPTIONS';

export interface ApiCall {
  protocol?: string;
  method: HttpMethod;
  host: string;
  path: string;
  requestHeaders?: Record<string, string | string[]>;
  body?: string;
  response?: string;
  responseHeaders?: Record<string, string | string[]>;
  status?: number;
  statusMessage?: string;
}

export interface Recorder {
  start: () => void;
  stop: () => void;
  getCalls: () => ApiCall[];
}

export interface HandlerError {
  name?: string;
  message?: string;
  statusCode?: string;
  // FIXME: I'm not sure if this is used anywhere!
  body?: Record<string, unknown>;
}

export interface WorkflowNodeError {
  message: string;
  error?: HandlerError;
  nonRetryable?: boolean;
}

export interface WorkflowNodeResult {
  workflowNodeId: string;
  nodeType: IntegrationNodeType;
  operationId?: string;
  started: ISODateTime;
  ended: ISODateTime;
  status: IntegrationEventStatus;
  input: unknown | undefined;
  output: unknown | undefined;
  callRecord?: ApiCall[];
  error?: WorkflowNodeError;
  /**
   * Is copied from an earlier attempt?
   *
   * When an action is re-tried, the earlier results can be copied (not mandatory) to the new action so that the new
   * action can be continued from the earlier attempt. The earlier results will be included as part of the new results
   * with the copied -flag set.
   */
  copied?: boolean;
}

export interface IntegrationEventOutput {
  started: ISODateTime;
  ended: ISODateTime;
  status: IntegrationEventStatus;
  workflowResults: WorkflowNodeResult[];
}

export interface IntegrationDefinitionReference {
  integrationId: string;
  name: string;
  triggerType:
    | IntegrationNodeType.PollingTrigger
    | IntegrationNodeType.WebhookTrigger
    | IntegrationNodeType.ErrorTrigger
    | IntegrationNodeType.ApiEndpointTrigger
    | undefined;
}

export interface IntegrationEvent {
  tenantId: string;
  integration: IntegrationDefinitionReference;
  eventContext?: EventContext;
  data?: unknown;
  output?: IntegrationEventOutput;
}

export enum ActionType {
  checkWorkflow = 'checkWorkflow',
  runWorkflow = 'runWorkflow',
}

export type IntegrationAction = Pick<
  FullQueueAction<IntegrationEvent>,
  | 'queueId'
  | 'actionId'
  | 'effectiveOn'
  | 'status'
  | 'content'
  | 'createdFromActionId'
  | 'retriedFromActionId'
  | 'retriedInActionId'
  | 'correlationId'
  | 'retryCount'
> & {
  /**
   * Action type
   *
   * @minLength 1
   */
  actionType: ActionType;
} & {
  tenantId: string;
  tenantName?: string;
  integration: Pick<IntegrationDefinitionBase, 'integrationId' | 'name'> & {
    triggerType?:
      | IntegrationNodeType.PollingTrigger
      | IntegrationNodeType.WebhookTrigger
      | IntegrationNodeType.ErrorTrigger
      | IntegrationNodeType.ApiEndpointTrigger;
  };
};

type BaseIntegrationActionLifecycleNotification = Pick<
  IntegrationAction,
  | 'queueId'
  | 'actionId'
  | 'effectiveOn'
  | 'actionType'
  | 'status'
  | 'createdFromActionId'
  | 'retriedFromActionId'
  | 'retriedInActionId'
  | 'correlationId'
  | 'retryCount'
  | 'integration'
  | 'tenantId'
  | 'tenantName'
>;

/**
 * The default action notification used throughout the application.
 */
export interface IntegrationActionLifecycleNotification
  extends BaseIntegrationActionLifecycleNotification {
  error?: {
    message: string;
    nonRetryable?: boolean;
    error?: HandlerError;
  };
}

export interface FailedIntegrationAction extends IntegrationAction {
  error?: {
    message: string;
    nonRetryable?: boolean;
    error?: HandlerError;
  };
}

export interface GetActionRequest {
  actionId: string;
}

export interface SearchActionRequest {
  actionId: string;
}

export interface SearchActionsRequest {
  actionId?: string;
  from?: string;
  until?: string;
  limit?: number;
  filterByIntegration?: string[];
  filterByStatus?: string[];
}

export interface RetryActionsRequest {
  integrationId: string;
  actionIds: string[];
}

export interface IntegrationOperationTestRequest {
  operation: IntegrationOperation;
  configuration?: SourceObject;
  runtimeConfiguration?: SourceObject;
  inputData?: unknown;
}

export interface IntegrationWorkflowTestRequest {
  workflow: IntegrationWorkflowDefinition;
  inputData: unknown;
}

export interface RunIntegrationWorkflowTestsRequest {
  workflow: IntegrationWorkflowDefinition;
}

export type IntegrationControlAction =
  // Run workflow immediately and then continue normally - note that integration must already be running
  | 'Run'
  // Start integration and run workflow immediately and then continue normally
  | 'StartAndRun'
  // Start integration normally
  | 'Start'
  // Pause integration - accepts messages
  | 'Pause'
  // Stop integration - will not accept messages
  | 'Stop';

export const allowedActionsForReadOnlyIntegrations = ['Run'];

export const allowedActionsPerIntegrationStatusForPolling: Record<
  IntegrationStatus,
  IntegrationControlAction[]
> = {
  Stopped: ['StartAndRun', 'Start'],
  Paused: ['Stop', 'StartAndRun', 'Start'],
  Running: ['Run', 'Stop', 'Pause'],
};

export const allowedActionsPerIntegrationStatusForWebhooks: Record<
  IntegrationStatus,
  IntegrationControlAction[]
> = {
  Stopped: ['Start'],
  Paused: ['Stop', 'Start'],
  Running: ['Stop', 'Pause'],
};

export interface ExportedTenantIntegrations {
  integrations: IntegrationDefinition[];
  operations: IntegrationOperation[];
}

export interface VersionedIntegrations {
  app: SystemglueAppName;
  version: string;
  description?: string;
  isStable?: boolean;
  isReadOnly?: boolean;
  createdOn: string;
  apis: ExposedApi[];
  integrations: IntegrationDefinition[];
  operations: IntegrationOperation[];
}

export interface DeleteIntegrationsRequest {
  integrationIds: string[];
}

export interface ExportIntegrationsRequest {
  app?: SystemglueAppName;
  integrationIds: string[];
}

export interface TentativeApiCommandResult {
  resultStatus: ApiResultStatus;
  error?: {
    httpCode?: number;
    message: string;
    details?: Record<string, unknown>;
  };
  resultId: string;
}

export interface ApiCommandResult<T = unknown> extends ApiResult<T> {
  resultId: string;
}

export interface SerializedStoredAccessToken {
  token: string;
  expiresAt: string;
}

export interface StoredAccessToken<ACCESS_TOKEN> {
  token: ACCESS_TOKEN;
  expiresAt: Date;
}

export interface AccessTokenStorage {
  get: (
    connection: SupportedModule
  ) => Promise<SerializedStoredAccessToken | undefined>;
  set: (
    connection: SupportedModule,
    token: SerializedStoredAccessToken
  ) => void;
}

export interface TenantIntegrationClient {
  getAction: (params: {
    actionId: string;
    queueId: string;
  }) => Promise<FullQueueAction<IntegrationEvent> | undefined>;
}
